import { TFunction } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table/InternalTable';
import { ReactNode } from 'react';
import { AssignedMachineType } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';
import { Checkbox } from 'lib/components/Checkbox/Checkbox';

interface IGetOperatorSelectedDevicesColumnsProps {
  assignedDevices: Optional<string>[];
  t: TFunction;
  togglePage: () => void;
  isAllDataInPageSelected: boolean;
}

export const getOperatorSelectedDevicesColumns = ({
  t,
  assignedDevices,
  isAllDataInPageSelected,
  togglePage,
}: IGetOperatorSelectedDevicesColumnsProps): ColumnsType<AssignedMachineType> => [
  {
    title: (
      <div className="operation-selected-devices__check">
        <Checkbox
          className={`
      ${assignedDevices.length === 0 ? 'operation-selected-devices__check--error' : ''}
      `}
          checked={isAllDataInPageSelected}
          onChange={togglePage}
        />
      </div>
    ),
    dataIndex: 'id',
    width: 50,
    key: 'id',
    render: (id): ReactNode => (
      <div className="operation-selected-devices__check">
        <Checkbox
          className={`
          ${assignedDevices.length === 0 ? 'operation-selected-devices__check--error' : ''}
          `}
          checked={assignedDevices.includes(id)}
        />
      </div>
    ),
  },
  {
    title: () => (
      <div className="operation-selected-devices__table__title">
        {t('operatorDetailsPanel.selectedDevices.table.columns.name')}
      </div>
    ),
    dataIndex: 'name',
    width: 250,
    key: 'machineName',
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: () => (
      <div className="operation-selected-devices__table__title">
        {t('operatorDetailsPanel.selectedDevices.table.columns.serialNo')}
      </div>
    ),
    dataIndex: 'serialNo',
    width: 140,
    key: 'serialNo',
    render: (serialNo): ReactNode => <div className="operation-selected-machines__serial-no">{serialNo}</div>,
  },
  {
    title: () => (
      <div className="operation-selected-devices__table__title">
        {t('operatorDetailsPanel.selectedDevices.table.columns.materialNo')}
      </div>
    ),
    dataIndex: 'materialNo',
    width: 150,
    key: 'materialNo',
    render: (materialNo): ReactNode => <div className="operation-selected-machines__material-no">{materialNo}</div>,
  },
  {
    title: () => (
      <div className="operation-selected-devices__table__title">
        {t('operatorDetailsPanel.selectedDevices.table.columns.status')}
      </div>
    ),
    dataIndex: 'status',
    width: 100,
    key: 'status',
    render: (status): ReactNode => <div className="operation-selected-machines__status">{status}</div>,
  },
];
