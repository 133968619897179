import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { DefaultOptionType, SelectValue } from 'antd/lib/select';
import { SearchOutlined } from '@ant-design/icons';
import { StyledInviteUserFormWrapper } from './InviteUserForm.styles';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { Select } from 'lib/components/Select/Select';
import { Input } from 'lib/components/Input/Input';
import { UserListActions } from 'app/modules/user-management/user-list/state/userListActions';
import { supportedLanguages } from 'app/cross-cutting-concerns/translations/Translations';
import { Role } from 'config/permissions';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { FeatureFlagSelectors } from 'app/cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { selectHasAccessToRobots } from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import * as UserListSelectors from 'app/modules/user-management/user-list/state/UserListSelectors';

const salutationDefaultValue = 'None';

const languageOptions = Object.entries(supportedLanguages).map(([key, values]) => ({
  value: key,
  content: values.name,
}));

const roleOptions = [
  { value: Role.Customer.STRATEGIC_MANAGER, content: 'userList.inviteUserForm.form.roles.siteManager' },
  { value: Role.Customer.ADMIN, content: 'userList.inviteUserForm.form.roles.administrator' },
];

export enum InviteUserTab {
  INVITE_USER = 'INVITE_USER',
  ADD_OPERATOR = 'ADD_OPERATOR',
}

export const InviteUserForm = (): JSX.Element => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const [formInstance] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);

  const isLoading = useSelector(UserListSelectors.selectInviteUserFormIsLoading);
  const isSitesLoading = useSelector(UserListSelectors.selectInviteUserFormIsSitesLoading);
  const sites = useSelector(UserListSelectors.selectInviteUserFormSites) || [];
  const [isShowAssignedSites, setIsShowAssignedSites] = useState(true);
  const [assignedSiteSearchInputValue, setAssignedSiteSearchInputValue] = useState('');

  const isRobotIntegrationEnabled = features.ROBOT_INTEGRATION;
  const haveRobots = useSelector(selectHasAccessToRobots);
  const isCustomerInviteEnabled = features.CUSTOMER_INVITE;

  const handleOk = useCallback(() => {
    formInstance.submit();
  }, [formInstance]);

  const onFinish = useCallback(
    (values: {
      salutation?: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string;
      language?: string;
      email: string;
      role: typeof Role.Customer.STRATEGIC_MANAGER | typeof Role.Customer.ADMIN;
      assignedSites: string[];
    }) => {
      analyticsLinkActivated({
        linkName: AnalyticsLink.INVITE_USER,
      });

      dispatch(
        UserListActions.inviteUserRequest({
          input: {
            salutation: values.salutation || salutationDefaultValue,
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
            phoneNumber: values.phoneNumber?.trim(),
            preferredLanguage: values.language,
            email: values.email.trim(),
            role: values.role,
            ...(values.role === Role.Customer.STRATEGIC_MANAGER && { assignedSiteIds: values.assignedSites }),
          },
        })
      );
    },
    [analyticsLinkActivated, dispatch]
  );

  const handleCancel = useCallback(() => {
    dispatch(UserListActions.hideUserManagementDrawer());
  }, [dispatch]);

  const handleRoleChange = useCallback((value: SelectValue) => {
    setIsShowAssignedSites(value === Role.Customer.STRATEGIC_MANAGER);
  }, []);

  const handleAssignedSiteSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setAssignedSiteSearchInputValue(event.target.value);
  };

  useEffect(() => {
    dispatch(UserListActions.getSitesListRequest());
  }, [dispatch]);

  useEffect(() => {
    formInstance.resetFields();
    setIsShowAssignedSites(true);
    setAssignedSiteSearchInputValue('');
  }, [formInstance, dispatch]);

  const tabs = [];
  if (isCustomerInviteEnabled) {
    tabs.push({
      label: t('userList.userManagementPanel.tabs.inviteNewUser'),
      key: InviteUserTab.INVITE_USER,
    });
  }

  if (isRobotIntegrationEnabled && haveRobots) {
    tabs.push({
      label: t('userList.userManagementPanel.tabs.addOperator'),
      key: InviteUserTab.ADD_OPERATOR,
    });
  }

  return (
    <StyledInviteUserFormWrapper>
      <Form
        form={formInstance}
        className="invite-user-form"
        name="invite-user"
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        initialValues={{
          language: languageOptions[0].value,
          role: Role.Customer.STRATEGIC_MANAGER,
          assignedSites: [],
        }}
      >
        <div className="invite-user-form__content">
          <Form.Item
            name="firstName"
            label={t('userList.inviteUserForm.form.firstName')}
            className="invite-user-form__input-group"
            required
            rules={[
              { required: true, message: t('userList.inviteUserForm.form.errors.firstNameRequired') },
              { min: 2, message: t('userList.inviteUserForm.form.errors.firstNameMinLength') },
              { max: 35, message: t('userList.inviteUserForm.form.errors.firstNameMaxLength') },
            ]}
          >
            <Input className="invite-user-form__input" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={t('userList.inviteUserForm.form.lastName')}
            className="invite-user-form__input-group"
            required
            rules={[
              { required: true, message: t('userList.inviteUserForm.form.errors.lastNameRequired') },
              { min: 2, message: t('userList.inviteUserForm.form.errors.lastNameMinLength') },
              { max: 35, message: t('userList.inviteUserForm.form.errors.lastNameMaxLength') },
            ]}
          >
            <Input className="invite-user-form__input" />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label={t('userList.inviteUserForm.form.phoneNumber')}
            className="invite-user-form__input-group"
            rules={[
              {
                pattern: /^\+\d{1,3}\s?\d{7,10}$/,
                message: t('userList.inviteUserForm.form.errors.phoneNumberInvalid'),
              },
            ]}
          >
            <Input className="invite-user-form__input" />
          </Form.Item>
          <Form.Item
            name="language"
            label={t('userList.inviteUserForm.form.language')}
            className="invite-user-form__input-group"
          >
            <Select
              dropdownVisibleState
              selectedOptionsOnTop
              className="invite-user-form__select"
              options={languageOptions.map(({ value, content }) => ({
                label: t(content),
                value,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label={t('userList.inviteUserForm.form.email')}
            className="invite-user-form__input-group"
            required
            rules={[
              { type: 'email', message: t('userList.inviteUserForm.form.errors.emailInvalid') },
              { required: true, message: t('userList.inviteUserForm.form.errors.emailRequired') },
            ]}
          >
            <Input className="invite-user-form__input" />
          </Form.Item>
          <Form.Item
            name="role"
            label={t('userList.inviteUserForm.form.role')}
            className="invite-user-form__input-group"
            required
            rules={[{ required: true, message: t('userList.inviteUserForm.form.errors.roleRequired') }]}
          >
            <Select
              dropdownVisibleState
              selectedOptionsOnTop
              className="invite-user-form__select"
              onChange={handleRoleChange}
              options={roleOptions.map(({ value, content }) => ({
                label: t(content),
                value,
              }))}
            />
          </Form.Item>
          {isShowAssignedSites && (
            <Form.Item
              name="assignedSites"
              label={t('userList.inviteUserForm.form.assignedSites')}
              className="invite-user-form__input-group"
              required
              rules={[{ required: true, message: t('userList.inviteUserForm.form.errors.assignedSitesRequired') }]}
            >
              <Select
                dropdownVisibleState
                selectedOptionsOnTop
                className="invite-user-form__select"
                mode="multiple"
                maxTagCount="responsive"
                placeholder={t('userList.inviteUserForm.form.placeholderAssignedSitesNull')}
                showSearch={false}
                searchValue={assignedSiteSearchInputValue}
                options={sites as unknown as DefaultOptionType[]}
                optionFilterProp="name"
                loading={isSitesLoading}
                fieldNames={{ label: 'name', value: 'id' }}
                onDropdownVisibleChange={(): void => setAssignedSiteSearchInputValue('')}
                dropdownRender={(menu): React.ReactElement => (
                  <>
                    <div className="invite-user-form__search-input-wrapper">
                      <Input
                        value={assignedSiteSearchInputValue}
                        className="invite-user-form__search-input"
                        placeholder={t('userList.inviteUserForm.form.searchForASite')}
                        prefix={<SearchOutlined />}
                        onChange={handleAssignedSiteSearchInputChange}
                      />
                    </div>
                    {menu}
                  </>
                )}
                tagRender={({ label, value }): React.ReactElement => (
                  <div>
                    {label}
                    {formInstance.getFieldValue('assignedSites')[
                      formInstance.getFieldValue('assignedSites').length - 1
                    ] === value
                      ? null
                      : ','}
                    &nbsp;
                  </div>
                )}
              />
            </Form.Item>
          )}
        </div>
        <div className="invite-user-form__actions">
          <SecondaryButton key="cancel-button" className="cancel-button" size="s" onClick={handleCancel}>
            {t('common.cancel')}
          </SecondaryButton>

          <PrimaryButton key="submit" className="submit-button" type="primary" onClick={handleOk} loading={isLoading}>
            {t('userList.inviteUserForm.invite')}
          </PrimaryButton>
        </div>
      </Form>
    </StyledInviteUserFormWrapper>
  );
};
