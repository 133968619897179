import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'ClanWebPro-Bold';
    src: url('/assets/fonts/ClanWebPro/ClanWebPro-Bold.woff') format("woff");
  }

  @font-face {
    font-family: 'ClanWebPro-Book';
    src: url('/assets/fonts/ClanWebPro/ClanWebPro-Book.woff') format("woff");
  }

  @font-face {
    font-family: 'ClanWebPro-Medium';
    src: url('/assets/fonts/ClanWebPro/ClanWebPro-Medium.woff') format("woff");
  }

  @font-face {
    font-family: 'ClanWebPro-NarrBlack';
    src: url('/assets/fonts/ClanWebPro/ClanOffcPro-NarrBlack.woff') format("woff");
  }

  @font-face {
    font-family: 'ClanWebPro-Bold-NarrBlack';
    src: url('/assets/fonts/ClanWebPro/ClanWebPro-NarrBlack.woff') format("woff");
  }

  @font-face {
    font-family: 'ClanWebPro-NarrBold';
    src: url('/assets/fonts/ClanWebPro/ClanWebPro-NarrBold.woff') format("woff");
  }

  @font-face {
    font-family: 'ClanWebPro-News';
    src: url('/assets/fonts/ClanWebPro/ClanWebPro-News.woff') format("woff");
  }

  @font-face {
    font-family: 'Kaercher-Icons';
    src: url('../app/assets/fonts/icons/kaercher_system_icons.eot?67423101');
    src: url('../app/assets/fonts/icons/kaercher_system_icons.eot?67423101#iefix') format('embedded-opentype'),
    url('../app/assets/fonts/icons/kaercher_system_icons.woff?67423101') format('woff'),
    url('../app/assets/fonts/icons/kaercher_system_icons.ttf?67423101') format('truetype'),
    url('../app/assets/fonts/icons/kaercher_system_icons.svg?67423101#kaercher_system_icons') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  html {
    height: 100%;
  }

  body {
    position: absolute;
    top:0;
    bottom:0;
    right:0;
    left:0;
  }

  #root {
    height: 100%;
    overflow: hidden;
  }

  .custom-antd-notification-error-message {
    // Title
    .ant-notification-notice-message {
      ${(props): string => props.theme.fontStyles.heading4};
      white-space: pre-wrap;
    }

    // Body
    .ant-notification-notice-description {
      ${(props): string => props.theme.fontStyles.p1.news};
      white-space: pre-wrap;
    }

    // Icon
    .ant-notification-notice-icon {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
`;
