import produce from 'immer';
import { AnyAction } from 'redux';
import { DEFAULT_PAGE_SIZE_VALUE, DEFAULT_PAGE_VALUE } from '../../../../../config/constants';
import { Optional } from '../../../../../lib/types/Optional';
import {
  Machine,
  NotificationOperator,
  Site,
  User,
} from '../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { PaginationTokens } from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { UserListAvailableFilters } from '../../interfaces/User.types';
import { UserListActions } from './userListActions';
import { getNextPaginationToken } from 'app/utils/infinite-scroll/infiniteScroll';

interface UserDataState {
  data: Optional<User[]>;
  totalCount: Optional<number>;
  isLoading: Optional<boolean>;
  isLoadingMoreData: Optional<boolean>;
  paginationTokens: PaginationTokens;
  nextPaginationToken: string | null;
  page: number;
  pageSize: number;
}

interface UserListOperatorState {
  data: Optional<NotificationOperator[]>;
  totalCount: Optional<number>;
  isLoading: Optional<boolean>;
  isLoadingMoreData: Optional<boolean>;
  paginationTokens: PaginationTokens;
  nextPaginationToken: string | null;
  page: number;
  pageSize: number;
}

export interface UserListState {
  activeUsers: UserDataState;
  invitedUsers: UserDataState;
  operators: UserListOperatorState;
  filters: {
    available: UserListAvailableFilters;
    active: {
      role: Optional<string>;
      period: {
        startDate: Optional<string>;
        endDate: Optional<string>;
      };
    };
    isLoading: boolean;
  };
  userManagement: {
    visible: boolean;
    isLoading: boolean;
  };
  operator: {
    operatorGet: {
      data: Optional<NotificationOperator>;
      isLoading: Optional<boolean>;
    };
    operatorForm: {
      isLoading: boolean;
      isSaving: boolean;
      data: Optional<NotificationOperator>;
      machines: {
        data: Optional<Machine[]>;
        isLoading: boolean;
      };
    };
  };
  user: {
    inviteUser: {
      isLoading: boolean;
      assignedSites: {
        isLoading: boolean;
        sites: Optional<Site[]>;
      };
    };
  };
}

export const initialState: UserListState = {
  activeUsers: {
    data: null,
    totalCount: null,
    isLoading: null,
    isLoadingMoreData: null,
    paginationTokens: {},
    nextPaginationToken: '',
    page: DEFAULT_PAGE_VALUE,
    pageSize: DEFAULT_PAGE_SIZE_VALUE,
  },
  invitedUsers: {
    data: null,
    totalCount: null,
    isLoading: null,
    isLoadingMoreData: null,
    paginationTokens: {},
    nextPaginationToken: '',
    page: DEFAULT_PAGE_VALUE,
    pageSize: DEFAULT_PAGE_SIZE_VALUE,
  },
  operators: {
    data: null,
    totalCount: null,
    isLoading: null,
    isLoadingMoreData: null,
    paginationTokens: {},
    nextPaginationToken: '',
    page: DEFAULT_PAGE_VALUE,
    pageSize: DEFAULT_PAGE_SIZE_VALUE,
  },
  filters: {
    available: {
      roles: [],
    },
    active: {
      role: undefined,
      period: {
        startDate: undefined,
        endDate: undefined,
      },
    },
    isLoading: false,
  },
  userManagement: {
    visible: false,
    isLoading: false,
  },
  operator: {
    operatorForm: {
      isLoading: false,
      isSaving: false,
      data: null,
      machines: {
        data: null,
        isLoading: false,
      },
    },
    operatorGet: {
      data: null,
      isLoading: false,
    },
  },
  user: {
    inviteUser: {
      isLoading: false,
      assignedSites: {
        isLoading: false,
        sites: null,
      },
    },
  },
};

export const userListReducer = (state = initialState, action: AnyAction): UserListState =>
  produce(state, draft => {
    switch (action.type) {
      case UserListActions.GET_USER_LIST_LOADING: {
        draft.activeUsers.isLoading = true;
        return draft;
      }

      case UserListActions.GET_USER_LIST_REQUEST: {
        draft.activeUsers.isLoading = true;
        return draft;
      }

      case UserListActions.GET_USER_LIST_SUCCESS: {
        const {
          users: {
            data,
            metadata: { totalCount, paginationToken },
          },
        } = action.payload;

        draft.activeUsers.isLoading = false;
        draft.activeUsers.data = data;
        draft.activeUsers.totalCount = totalCount;
        draft.activeUsers.nextPaginationToken = getNextPaginationToken(paginationToken);

        if (paginationToken) {
          draft.activeUsers.paginationTokens[draft.activeUsers.page + 1] = paginationToken;
        }

        return draft;
      }

      case UserListActions.GET_USER_LIST_ERROR: {
        draft.activeUsers.isLoading = false;
        return draft;
      }

      case UserListActions.GET_USER_LIST_MORE_DATA_REQUEST: {
        draft.activeUsers.isLoadingMoreData = true;
        return draft;
      }

      case UserListActions.GET_USER_LIST_MORE_DATA_SUCCESS: {
        const {
          users: {
            data,
            metadata: { paginationToken },
          },
        } = action.payload;

        draft.activeUsers.isLoadingMoreData = false;
        draft.activeUsers.data = (draft.activeUsers.data || []).concat(data);
        draft.activeUsers.nextPaginationToken = getNextPaginationToken(paginationToken);

        return draft;
      }

      case UserListActions.GET_USER_LIST_MORE_DATA_ERROR: {
        draft.activeUsers.isLoadingMoreData = false;
        return draft;
      }

      case UserListActions.CHANGE_TABLE_PAGE: {
        const { page } = action.payload;

        draft.activeUsers.page = page;
        return draft;
      }

      case UserListActions.CHANGE_TABLE_PAGE_SIZE: {
        const { pageSize } = action.payload;

        draft.activeUsers.paginationTokens = {};
        draft.activeUsers.page = DEFAULT_PAGE_VALUE;
        draft.activeUsers.pageSize = pageSize;
        return draft;
      }

      case UserListActions.GET_INVITED_USER_LIST_REQUEST: {
        draft.invitedUsers.isLoading = true;
        return draft;
      }

      case UserListActions.GET_INVITE_USER_LIST_LOADING: {
        draft.invitedUsers.isLoading = true;
        return draft;
      }

      case UserListActions.GET_INVITED_USER_LIST_SUCCESS: {
        const {
          users: {
            data,
            metadata: { totalCount, paginationToken },
          },
        } = action.payload;

        draft.invitedUsers.isLoading = false;
        draft.invitedUsers.data = data;
        draft.invitedUsers.totalCount = totalCount;
        draft.invitedUsers.nextPaginationToken = getNextPaginationToken(paginationToken);

        if (paginationToken) {
          draft.invitedUsers.paginationTokens[draft.invitedUsers.page + 1] = paginationToken;
        }

        return draft;
      }

      case UserListActions.GET_INVITED_USER_LIST_ERROR: {
        draft.invitedUsers.isLoading = false;
        return draft;
      }

      case UserListActions.GET_INVITED_USER_LIST_MORE_DATA_REQUEST: {
        draft.invitedUsers.isLoadingMoreData = true;
        return draft;
      }

      case UserListActions.GET_INVITED_USER_LIST_MORE_DATA_SUCCESS: {
        const {
          users: {
            data,
            metadata: { paginationToken },
          },
        } = action.payload;

        draft.invitedUsers.isLoadingMoreData = false;
        draft.invitedUsers.data = (draft.invitedUsers.data || []).concat(data);
        draft.invitedUsers.nextPaginationToken = getNextPaginationToken(paginationToken);
        return draft;
      }

      case UserListActions.GET_INVITED_USER_LIST_MORE_DATA_ERROR: {
        draft.invitedUsers.isLoadingMoreData = false;
        return draft;
      }

      case UserListActions.CHANGE_INVITED_USER_TABLE_PAGE: {
        const { page } = action.payload;

        draft.invitedUsers.page = page;
        return draft;
      }

      case UserListActions.CHANGE_INVITED_USER_TABLE_PAGE_SIZE: {
        const { pageSize } = action.payload;

        draft.invitedUsers.paginationTokens = {};
        draft.invitedUsers.page = DEFAULT_PAGE_VALUE;
        draft.invitedUsers.pageSize = pageSize;
        return draft;
      }

      case UserListActions.SET_ACTIVE_PERIOD_FILTER: {
        const { period } = action.payload;

        draft.filters.active.period = period;
        return draft;
      }

      case UserListActions.SET_ACTIVE_ROLE_FILTER: {
        const { role } = action.payload;

        draft.filters.active.role = role;
        draft.activeUsers.page = DEFAULT_PAGE_VALUE;
        draft.activeUsers.paginationTokens = {};
        return draft;
      }

      case UserListActions.GET_USER_LIST_FILTERS_REQUEST: {
        draft.filters.isLoading = true;
        return draft;
      }

      case UserListActions.GET_USER_LIST_FILTERS_SUCCESS: {
        draft.filters.isLoading = false;
        draft.filters.available = action.payload;

        return draft;
      }

      case UserListActions.GET_USER_LIST_FILTERS_ERROR: {
        draft.filters.isLoading = false;
        return draft;
      }

      case UserListActions.SET_USER_ROLE_REQUEST: {
        draft.activeUsers.isLoading = true;
        return draft;
      }

      case UserListActions.SET_USER_ROLE_SUCCESS: {
        const id = action.payload?.userUpdate?.data?.id;
        const role = action.payload?.userUpdate?.data?.role;

        draft.activeUsers.isLoading = false;

        if (!draft.activeUsers.data || !id || !role) return draft;

        const index = draft.activeUsers.data.findIndex(element => element.id === id);

        if (index !== -1) {
          draft.activeUsers.data[index].role = role;
        }

        return draft;
      }

      case UserListActions.SET_USER_ROLE_ERROR: {
        draft.activeUsers.isLoading = false;
        return draft;
      }

      case UserListActions.INVITE_USER_REQUEST: {
        draft.invitedUsers.isLoading = true;
        return draft;
      }
      case UserListActions.INVITE_USER_SUCCESS: {
        draft.invitedUsers.isLoading = false;
        return draft;
      }
      case UserListActions.INVITE_USER_ERROR: {
        draft.invitedUsers.isLoading = false;
        return draft;
      }

      case UserListActions.RESET_STATE: {
        return initialState;
      }

      case UserListActions.GET_USER_LIST_OPERATOR_REQUEST: {
        draft.operators.isLoading = true;
        return draft;
      }
      case UserListActions.GET_USER_LIST_OPERATOR_SUCCESS: {
        const {
          notificationListOperator: {
            data,
            metadata: { totalCount, paginationToken },
          },
        } = action.payload;

        draft.operators.isLoading = false;
        draft.operators.data = data;
        draft.operators.totalCount = totalCount;
        draft.operators.nextPaginationToken = getNextPaginationToken(paginationToken);

        if (paginationToken) {
          draft.operators.paginationTokens[draft.operators.page + 1] = paginationToken;
        }

        return draft;
      }
      case UserListActions.GET_USER_LIST_OPERATOR_ERROR: {
        draft.operators.isLoading = false;
        return draft;
      }

      case UserListActions.GET_USER_LIST_OPERATOR_MORE_DATA_REQUEST: {
        draft.operators.isLoadingMoreData = true;
        return draft;
      }
      case UserListActions.GET_USER_LIST_OPERATOR_MORE_DATA_SUCCESS: {
        const {
          notificationListOperator: {
            data,
            metadata: { paginationToken },
          },
        } = action.payload;

        draft.operators.isLoadingMoreData = false;
        draft.operators.data = (draft.operators.data || []).concat(data);
        draft.operators.nextPaginationToken = getNextPaginationToken(paginationToken);

        return draft;
      }
      case UserListActions.GET_USER_LIST_OPERATOR_MORE_DATA_ERROR: {
        draft.operators.isLoadingMoreData = false;
        return draft;
      }
      case UserListActions.CHANGE_USER_LIST_OPERATOR_TABLE_PAGE: {
        const { page } = action.payload;

        draft.operators.page = page;
        return draft;
      }
      case UserListActions.CHANGE_USER_LIST_OPERATOR_TABLE_PAGE_SIZE: {
        const { pageSize } = action.payload;

        draft.operators.paginationTokens = {};
        draft.operators.page = DEFAULT_PAGE_VALUE;
        draft.operators.pageSize = pageSize;
        return draft;
      }

      case UserListActions.ADD_OPERATOR_FORM_RESET_STATE: {
        draft.operator.operatorGet = initialState.operator.operatorGet;
        return draft;
      }

      case UserListActions.USER_MANAGEMENT_DRAWER_IS_LOADING: {
        draft.userManagement.isLoading = true;
        return draft;
      }

      case UserListActions.USER_MANAGEMENT_DRAWER_IS_NOT_LOADING: {
        draft.userManagement.isLoading = false;
        return draft;
      }

      case UserListActions.SHOW_USER_MANAGEMENT_DRAWER: {
        draft.userManagement.visible = true;
        return draft;
      }

      case UserListActions.HIDE_USER_MANAGEMENT_DRAWER: {
        draft.userManagement.visible = false;
        return draft;
      }

      case UserListActions.GET_MACHINE_LIST_WITHOUT_IMAGE_REQUEST: {
        draft.operator.operatorForm.machines.isLoading = true;
        return draft;
      }

      case UserListActions.GET_MACHINE_LIST_WITHOUT_IMAGE_SUCCESS: {
        const {
          machines: { data },
        } = action.payload;

        draft.operator.operatorForm.machines.isLoading = false;
        draft.operator.operatorForm.machines.data = data;

        return draft;
      }

      case UserListActions.GET_MACHINE_LIST_WITHOUT_IMAGE_ERROR: {
        draft.operator.operatorForm.machines.isLoading = false;
        return draft;
      }

      case UserListActions.RESET_MACHINES_STATE: {
        draft.operator.operatorForm.machines.data = initialState.operator.operatorForm.machines.data;
        draft.operator.operatorForm.machines.isLoading = initialState.operator.operatorForm.machines.isLoading;
        return draft;
      }

      case UserListActions.RESET_STATE_SAVING: {
        draft.operator.operatorForm.data = initialState.operator.operatorForm.data;
        return draft;
      }

      case UserListActions.SAVE_OPERATOR_REQUEST: {
        draft.operator.operatorForm.isSaving = true;
        draft.operator.operatorForm.isLoading = true;
        return draft;
      }

      case UserListActions.SAVE_OPERATOR_SUCCESS: {
        draft.operator.operatorForm.data = action.payload?.notificationUpsertOperator?.data || null;
        draft.operator.operatorForm.isSaving = false;
        draft.operator.operatorForm.isLoading = false;
        return draft;
      }

      case UserListActions.SAVE_OPERATOR_ERROR: {
        draft.operator.operatorForm.isSaving = false;
        draft.operator.operatorForm.isLoading = false;
        return draft;
      }

      case UserListActions.GET_OPERATOR_REQUEST: {
        draft.operator.operatorGet.isLoading = true;
        return draft;
      }

      case UserListActions.GET_OPERATOR_SUCCESS: {
        draft.operator.operatorGet.isLoading = false;
        draft.operator.operatorGet.data = action.payload?.notificationGetOperator?.data || null;
        return draft;
      }

      case UserListActions.GET_OPERATOR_ERROR: {
        draft.operator.operatorGet.isLoading = false;
        return draft;
      }

      case UserListActions.ADD_OPERATOR_FORM_IS_LOADING: {
        draft.operator.operatorForm.isLoading = true;
        return draft;
      }

      case UserListActions.ADD_OPERATOR_FORM_IS_NOT_LOADING: {
        draft.operator.operatorForm.isLoading = false;
        return draft;
      }

      case UserListActions.GET_SITES_LIST_REQUEST: {
        draft.user.inviteUser.assignedSites.isLoading = true;
        return draft;
      }

      case UserListActions.GET_SITES_LIST_SUCCESS: {
        const {
          sites: { data },
        } = action.payload;

        draft.user.inviteUser.assignedSites.isLoading = false;
        draft.user.inviteUser.assignedSites.sites = data;
        return draft;
      }

      case UserListActions.GET_SITES_LIST_ERROR: {
        draft.user.inviteUser.assignedSites.isLoading = false;
        return draft;
      }

      case UserListActions.INVITE_USER_FORM_IS_LOADING: {
        draft.user.inviteUser.isLoading = true;
        return draft;
      }

      case UserListActions.INVITE_USER_FORM_IS_NOT_LOADING: {
        draft.user.inviteUser.isLoading = false;
        return draft;
      }

      default:
        return draft;
    }
  });
