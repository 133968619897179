import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isNil, noop } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import { pages } from '../../../../../../config/pages';
import { AnalyticsLink } from '../../../../../cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { AnalyticsActions } from '../../../../../cross-cutting-concerns/analytics/state/analyticsSlice';
import { FeatureFlagSelectors } from '../../../../../cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { useCurrentPathPattern } from '../../../../../cross-cutting-concerns/routing/hooks/useCurrentPathPattern';
import * as cleaningReportListSelectors from '../../../../cleaning/cleaning-report-list/state/cleaningReportListSelectors';
import { getRoleTranslationString } from '../../../utils/getRoleTranslationString';
import { UserListActions } from '../../state/userListActions';
import { UserListSelectors } from '../../state/UserListSelectors';
import { StyledUserList } from './UserList.styles';
import { UserListSystem } from './UserListSystem/UserListSystem';
import { UserListOperator } from './UserListOperator/UserListOperator';
import { UserManagementDrawer } from './UserManagementDrawer/UserManagementDrawer';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { PermissionGuard } from 'app/cross-cutting-concerns/authentication/components/PermissionGuard/PermissionGuard';
import { Permission } from 'config/permissions';
import { ReactComponent as UserAdd } from 'lib/assets/images/User_Add.svg';
import { ALL_VALUE_SELECT_OPTION } from 'config/constants';
import { selectHasAccessToRobots } from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { Tabs } from 'lib/components/Tabs/Tabs';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { Select } from 'lib/components/Select/Select';
import { Input } from 'lib/components/Input/Input';
import { RangePicker } from 'lib/components/RangePicker/RangePicker';

export enum UserListTab {
  SYSTEM_USERS = 'SYSTEM_USERS',
  OPERATORS = 'OPERATORS',
}

export const UserList = (): JSX.Element => {
  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [today, setToday] = useState<Date>();
  const [activeTab, setActiveTab] = useState(UserListTab.SYSTEM_USERS);

  const areFiltersLoading = useSelector(UserListSelectors.selectAreFiltersLoading);
  const countAuthorized = useSelector(UserListSelectors.selectActiveUserTotalCount);
  const countOutstandingInvitations = useSelector(UserListSelectors.selectInvitedUserTotalCount);
  const availableFilters = useSelector(UserListSelectors.selectFilters);
  const startDate =
    useSelector(cleaningReportListSelectors.selectPeriodStartDate) || DateTime.beginningOfEpochTime()?.toISOString();
  const endDate = useSelector(cleaningReportListSelectors.selectPeriodEndDate) || today?.toISOString();
  const currentPathPattern = useCurrentPathPattern();

  const isSortingAndFilteringEnabled = features.USER_LIST_FILTERS_AND_SORTING;
  const isRobotIntegrationEnabled = features.ROBOT_INTEGRATION;

  const haveRobots = useSelector(selectHasAccessToRobots);

  useEffect(() => {
    if (isNil(currentPathPattern)) {
      return;
    }

    const pageName = pages[currentPathPattern]?.pageName;
    const pageId = pages[currentPathPattern]?.pageId;
    const pageNameHasInvalidValue = pageName.includes('undefined');

    if (
      isNil(pageName) ||
      pageNameHasInvalidValue ||
      isNil(pageId) ||
      isNil(countAuthorized) ||
      isNil(countOutstandingInvitations)
    ) {
      return;
    }

    dispatch(
      AnalyticsActions.setUserListPageInfo({
        pageInfo: {
          pageName,
          pathPattern: currentPathPattern,
          pageId: pages[currentPathPattern]?.pageId,
        },
        userAttributes: {
          countAuthorized,
          countOutstandingInvitations,
        },
      })
    );
  }, [countAuthorized, countOutstandingInvitations, currentPathPattern, dispatch]);

  useEffect(() => {
    setToday(DateTime.today());
  }, []);

  useEffect(
    () => (): void => {
      dispatch(UserListActions.resetState());
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isSortingAndFilteringEnabled) return;

    dispatch(UserListActions.getUserListFiltersRequest());
  }, [dispatch, isSortingAndFilteringEnabled]);

  const handleRolesFilterChange = (): void => {
    noop();
  };

  const handleSearchChange = (): void => {
    noop();
  };

  const onRangePickerChange = (dates: [Dayjs | null, Dayjs | null] | null): void => {
    if (dates && dates[0] && dates[1]) {
      analyticsLinkActivated({
        linkName: AnalyticsLink.PERMISSIONS_PERIOD_FILTER,
      });

      dispatch(
        UserListActions.setActivePeriodFilter({
          startDate: dates[0].toISOString(),
          endDate: dates[1].toISOString(),
        })
      );
    }
  };

  const handleTabChange = (activeTabKey: string): void => {
    setActiveTab(activeTabKey as UserListTab);

    if (activeTabKey === UserListTab.OPERATORS) {
      analyticsLinkActivated({
        linkName: AnalyticsLink.LIST_OPERATORS,
      });
    } else {
      analyticsLinkActivated({
        linkName: AnalyticsLink.LIST_SYSTEM_USERS,
      });
    }
  };

  const showUserManagementDrawer = (): void => {
    dispatch(UserListActions.showUserManagementDrawer());
  };

  const tabs = [
    {
      label: t('userList.tabs.systemUsers'),
      key: UserListTab.SYSTEM_USERS,
    },
  ];

  if (isRobotIntegrationEnabled && haveRobots) {
    tabs.push({
      label: t('userList.tabs.operators'),
      key: UserListTab.OPERATORS,
    });
  }

  return (
    <>
      <StyledUserList className="user-list">
        <div className="user-list__header">
          <div className="user-list__header-content">
            <div className="user-list__container--fluid">
              <div className="user-list__page-info">
                <h1 className="user-list__title">{t('userList.title')}</h1>
                <PermissionGuard requiredPermissions={[Permission.Customer.User.SEND_INVITATION]}>
                  <PrimaryButton
                    size="m"
                    className="user-list__invite-user-button"
                    onClick={showUserManagementDrawer}
                    icon={<UserAdd width={20} height={20} className="user-list__invite-user-button__icon" />}
                  >
                    {t('userList.inviteUser')}
                  </PrimaryButton>
                </PermissionGuard>
              </div>

              <div className="user-list__header-controls">
                {isSortingAndFilteringEnabled && (
                  <div className="user-list__filters">
                    <div className="user-list__select-wrapper">
                      <span className="user-list__filter-label">{t('userList.filter.roles')}</span>
                      <Select
                        dropdownVisibleState
                        defaultValue={ALL_VALUE_SELECT_OPTION}
                        className="user-list__select"
                        loading={areFiltersLoading}
                        onChange={handleRolesFilterChange}
                        options={[
                          { label: t('common.all'), value: ALL_VALUE_SELECT_OPTION },
                          ...(availableFilters?.roles || []).map(availableRole => ({
                            label: t(getRoleTranslationString(availableRole)),
                            value: availableRole,
                          })),
                        ]}
                      />
                    </div>

                    <div className="user-list__date-range-picker-wrapper">
                      <span className="user-list__filter-label">{t('common.period')}</span>
                      <RangePicker onChange={onRangePickerChange} value={[dayjs(startDate), dayjs(endDate)]} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="user-list__body">
          <div className="user-list__body-content">
            <div className="user-list__container--fluid">
              <div className="user-list__body-controls">
                {isSortingAndFilteringEnabled && (
                  <div className="user-list__search-input">
                    {/* TODO: [0.9] Implement search */}
                    <Input
                      placeholder={t('common.searchFor')}
                      suffix={
                        <span className="user-list__search-bar-icon">
                          <SearchOutlined />
                        </span>
                      }
                      onChange={handleSearchChange}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="user-list__container--fluid">
              <div className="user-list__tabs">
                <Tabs destroyInactiveTabPane={true} items={tabs} onChange={handleTabChange} activeKey={activeTab} />
              </div>
            </div>
            <div className="user-list__container--fluid">
              {activeTab === UserListTab.SYSTEM_USERS && <UserListSystem />}
              {activeTab === UserListTab.OPERATORS && <UserListOperator />}
            </div>
          </div>
        </div>
      </StyledUserList>
      <UserManagementDrawer />
    </>
  );
};
