import { singleton } from 'tsyringe';
import {
  OperatorClient,
  OperatorClientDeleteOptions,
  OperatorClientListOptions,
  OperatorModalClientOptions,
} from './OperatorClient';
import {
  GetOperatorResponse,
  NotificationDeleteOperatorResponse,
  NotificationOperatorsResponse,
  OperatorResponse,
} from './interfaces/Operator.types';
import { Optional } from 'lib/types/Optional';
import { MutationNotificationUpsertOperatorArgs } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export type OperatorServiceListOptions = OperatorClientListOptions;
export type OperatorModalServiceOptions = OperatorModalClientOptions;
export type OperatorServiceDeleteOptions = OperatorClientDeleteOptions;

@singleton()
export class OperatorService {
  constructor(private operatorClient: OperatorClient) {}

  public list = async ({
    paginationOptions,
  }: OperatorServiceListOptions): Promise<Optional<NotificationOperatorsResponse>> => {
    const { data } = await this.operatorClient.list({ paginationOptions });
    return data;
  };

  public upsert = async ({ input }: MutationNotificationUpsertOperatorArgs): Promise<Optional<OperatorResponse>> => {
    const { data } = await this.operatorClient.upsert({ input });
    return data;
  };

  public get = async (id: string): Promise<Optional<GetOperatorResponse>> => {
    const { data } = await this.operatorClient.get(id);
    return data;
  };

  public delete = async ({
    id,
  }: OperatorServiceDeleteOptions): Promise<Optional<NotificationDeleteOperatorResponse>> => {
    const { data } = await this.operatorClient.delete({ id });
    return data;
  };
}
