import React from 'react';
import { StyledPrivacyPolicyPage } from './PrivacyPolicyPage.styles';
import { PrivacyPolicy } from 'app/modules/privacy-policy/PrivacyPolicy';
import { AppFooter } from 'app/components/app-layout/AppFooter/AppFooter';
import { LoginHeader } from 'app/cross-cutting-concerns/authentication/components/LoginHeader/LoginHeader';

export const PrivacyPolicyPage = (): JSX.Element => (
  <StyledPrivacyPolicyPage>
    <LoginHeader />
    <PrivacyPolicy />
    <AppFooter />
  </StyledPrivacyPolicyPage>
);
