import { Optional } from '../../../../../lib/types/Optional';
import {
  Machine,
  NotificationOperator,
  Site,
  User,
} from '../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { IState } from '../../../../cross-cutting-concerns/state-management/interfaces/IState';
import { PaginationTokens } from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { UserListAvailableFilters } from '../../interfaces/User.types';

export class UserListSelectors {
  public static selectActiveUserData = (state: IState): Optional<User[]> =>
    state.modules['user-management']['user-list'].activeUsers.data;

  public static selectActiveUserTotalCount = (state: IState): Optional<number> =>
    state.modules['user-management']['user-list'].activeUsers.totalCount;

  public static selectActiveUserIsLoading = (state: IState): Optional<boolean> =>
    state.modules['user-management']['user-list'].activeUsers.isLoading;

  public static selectActiveUserIsLoadingMoreData = (state: IState): Optional<boolean> =>
    state.modules['user-management']['user-list'].activeUsers.isLoadingMoreData;

  public static selectActiveUserPaginationTokens = (state: IState): PaginationTokens =>
    state.modules['user-management']['user-list'].activeUsers.paginationTokens;

  public static selectActiveUserNextPaginationToken = (state: IState): string | null =>
    state.modules['user-management']['user-list'].activeUsers.nextPaginationToken;

  public static selectActiveUserPage = (state: IState): number =>
    state.modules['user-management']['user-list'].activeUsers.page;

  public static selectActiveUserPageSize = (state: IState): number =>
    state.modules['user-management']['user-list'].activeUsers.pageSize;

  public static selectInvitedUserData = (state: IState): Optional<User[]> =>
    state.modules['user-management']['user-list'].invitedUsers.data;

  public static selectInvitedUserTotalCount = (state: IState): Optional<number> =>
    state.modules['user-management']['user-list'].invitedUsers.totalCount;

  public static selectInvitedUserIsLoading = (state: IState): Optional<boolean> =>
    state.modules['user-management']['user-list'].invitedUsers.isLoading;

  public static selectInvitedUserIsLoadingMoreData = (state: IState): Optional<boolean> =>
    state.modules['user-management']['user-list'].invitedUsers.isLoadingMoreData;

  public static selectInvitedUserPaginationTokens = (state: IState): PaginationTokens =>
    state.modules['user-management']['user-list'].invitedUsers.paginationTokens;

  public static selectInvitedUserNextPaginationToken = (state: IState): string | null =>
    state.modules['user-management']['user-list'].invitedUsers.nextPaginationToken;

  public static selectInvitedUserPage = (state: IState): number =>
    state.modules['user-management']['user-list'].invitedUsers.page;

  public static selectInvitedUserPageSize = (state: IState): number =>
    state.modules['user-management']['user-list'].invitedUsers.pageSize;

  public static selectFilters = (state: IState): Optional<UserListAvailableFilters> =>
    state.modules['user-management']['user-list'].filters.available;

  public static selectAreFiltersLoading = (state: IState): boolean =>
    state.modules['user-management']['user-list'].filters.isLoading;

  public static selectOperatorData = (state: IState): Optional<NotificationOperator[]> =>
    state.modules['user-management']['user-list'].operators.data;

  public static selectOperatorTotalCount = (state: IState): Optional<number> =>
    state.modules['user-management']['user-list'].operators.totalCount;

  public static selectOperatorIsLoading = (state: IState): Optional<boolean> =>
    state.modules['user-management']['user-list'].operators.isLoading;

  public static selectOperatorIsLoadingMoreData = (state: IState): Optional<boolean> =>
    state.modules['user-management']['user-list'].operators.isLoadingMoreData;

  public static selectOperatorPaginationTokens = (state: IState): PaginationTokens =>
    state.modules['user-management']['user-list'].operators.paginationTokens;

  public static selectOperatorNextPaginationToken = (state: IState): string | null =>
    state.modules['user-management']['user-list'].operators.nextPaginationToken;

  public static selectOperatorPage = (state: IState): number =>
    state.modules['user-management']['user-list'].operators.page;

  public static selectOperatorPageSize = (state: IState): number =>
    state.modules['user-management']['user-list'].operators.pageSize;
}

export const selectInviteUserFormIsLoading = (state: IState): boolean =>
  state.modules['user-management']['user-list'].user.inviteUser.isLoading;

export const selectInviteUserFormIsSitesLoading = (state: IState): boolean =>
  state.modules['user-management']['user-list'].user.inviteUser.assignedSites.isLoading;

export const selectInviteUserFormSites = (state: IState): Optional<Site[]> =>
  state.modules['user-management']['user-list'].user.inviteUser.assignedSites.sites;

export const selectOperatorFormIsLoading = (state: IState): boolean =>
  state.modules['user-management']['user-list'].operator.operatorForm.isLoading;

export const selectOperatorFormIsSaving = (state: IState): boolean =>
  state.modules['user-management']['user-list'].operator.operatorForm.isSaving;

export const selectOperatorSavingData = (state: IState): Optional<NotificationOperator> =>
  state.modules['user-management']['user-list'].operator.operatorForm.data;

export const selectOperatorFormMachines = (state: IState): Optional<Machine[]> =>
  state.modules['user-management']['user-list'].operator.operatorForm.machines.data;

export const selectOperatorFormMachinesAreLoading = (state: IState): boolean =>
  state.modules['user-management']['user-list'].operator.operatorForm.machines.isLoading;

export const selectOperatorGetData = (state: IState): Optional<NotificationOperator> =>
  state.modules['user-management']['user-list'].operator.operatorGet.data;

export const selectOperatorGetIsLoading = (state: IState): Optional<boolean> =>
  state.modules['user-management']['user-list'].operator.operatorGet.isLoading;

export const selectUserManagementDrawerVisible = (state: IState): boolean =>
  state.modules['user-management']['user-list'].userManagement.visible;

export const selectUserManagementDrawerIsLoading = (state: IState): boolean =>
  state.modules['user-management']['user-list'].userManagement.isLoading;
