import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDeleteOperatorId,
  selectDeleteOperatorModalIsLoading,
  selectDeleteOperatorModalVisible,
} from '../../state/userModalsSelectors';
import { UserModalsActions } from '../../state/userModalsActions';
import { StyledDeleteOperatorModal } from './DeleteOperatorModal.styles';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';

export const DeleteOperatorModal = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const analyticsLinkActivated = useAnalyticsLinkActivated();

  const isVisible = useSelector(selectDeleteOperatorModalVisible);
  const isLoading = useSelector(selectDeleteOperatorModalIsLoading);
  const id = useSelector(selectDeleteOperatorId);

  const handleOk = useCallback(() => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.DELETE_OPERATOR,
    });

    dispatch(UserModalsActions.deleteOperatorRequest({ id }));
  }, [analyticsLinkActivated, dispatch, id]);

  const handleCancel = useCallback(() => {
    dispatch(UserModalsActions.hideDeleteOperatorModal());
  }, [dispatch]);

  return (
    <StyledDeleteOperatorModal
      className="delete-operator-modal"
      title={t('userList.operator.deleteModal.title')}
      centered
      open={isVisible}
      destroyOnClose={true}
      closable={false}
      footer={[
        <SecondaryButton
          size="m"
          disabled={isLoading}
          onClick={handleCancel}
          className="delete-operator-modal__cancel-button"
          key="cancel-button"
        >
          {t('common.cancel')}
        </SecondaryButton>,
        <PrimaryButton
          size="m"
          key="submit"
          className="delete-operator-modal__submit-button"
          type="primary"
          loading={isLoading}
          onClick={handleOk}
        >
          {t('common.ok')}
        </PrimaryButton>,
      ]}
    >
      {t('userList.operator.deleteModal.description')}
    </StyledDeleteOperatorModal>
  );
};
