import { createSlice } from '@reduxjs/toolkit';
import {
  IDeleteInvitedUserErrorAction,
  IDeleteInvitedUserRequestAction,
  IDeleteInvitedUserSuccessAction,
  IDeleteUserErrorAction,
  IDeleteUserModalIsLoadingAction,
  IDeleteUserModalIsNotLoadingAction,
  IDeleteUserRequestAction,
  IDeleteUserSuccessAction,
  IHideDeleteUserModalAction,
  IShowDeleteUserModalAction,
} from './userModalsActions.type';
import { Status } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface UserModalState {
  deleteUser: {
    visible: boolean;
    isLoading: boolean;
    email: string;
    status: Status;
    deletingEmail: { [key: string]: string };
  };
}

export const initialState: UserModalState = {
  deleteUser: {
    visible: false,
    isLoading: false,
    email: '',
    status: Status.Active,
    deletingEmail: {},
  },
};

const userModalsSlice = createSlice({
  name: 'deleteUserModal',
  initialState,
  reducers: {
    showDeleteUserModal: (state, action: IShowDeleteUserModalAction) => {
      state.deleteUser.visible = true;
      state.deleteUser.email = action.payload.email;
      state.deleteUser.status = action.payload.status;
    },
    hideDeleteUserModal: (state, _action: IHideDeleteUserModalAction) => {
      state.deleteUser.visible = false;
      state.deleteUser.email = initialState.deleteUser.email;
    },
    deleteUserModalIsLoading: (state, _action: IDeleteUserModalIsLoadingAction) => {
      state.deleteUser.isLoading = true;
    },
    deleteUserModalIsNotLoading: (state, _action: IDeleteUserModalIsNotLoadingAction) => {
      state.deleteUser.isLoading = false;
    },

    deleteUserRequest: (state, _action: IDeleteUserRequestAction) => {
      state.deleteUser.isLoading = true;
      return state;
    },
    deleteUserSuccess: (state, _action: IDeleteUserSuccessAction) => {
      state.deleteUser.email = initialState.deleteUser.email;
      state.deleteUser.isLoading = false;
      return state;
    },
    deleteUserError: (state, _action: IDeleteUserErrorAction) => {
      state.deleteUser.email = initialState.deleteUser.email;
      state.deleteUser.isLoading = false;
      return state;
    },

    deleteInvitedRequest: (state, _action: IDeleteInvitedUserRequestAction) => {
      state.deleteUser.isLoading = true;
      state.deleteUser.deletingEmail[_action.payload.input.email] = 'loading';
      return state;
    },
    deleteInvitedSuccess: (state, _action: IDeleteInvitedUserSuccessAction) => {
      state.deleteUser.email = initialState.deleteUser.email;
      state.deleteUser.isLoading = false;
      state.deleteUser.deletingEmail[_action.payload.email] = 'success';
      return state;
    },
    deleteInvitedError: (state, _action: IDeleteInvitedUserErrorAction) => {
      state.deleteUser.email = initialState.deleteUser.email;
      state.deleteUser.isLoading = false;
      state.deleteUser.deletingEmail[_action.payload.email] = 'error';
      return state;
    },
  },
});

export const userModalsActions = userModalsSlice.actions;
export const userModalReducer = userModalsSlice.reducer;
