import { StyledTermsAndConditionsPage } from './TermsAndConditionsPage.styles';
import { TermsAndConditions } from 'app/modules/terms-conditions/components/TermsAndConditions/TermsAndConditions';
import { AppFooter } from 'app/components/app-layout/AppFooter/AppFooter';
import { LoginHeader } from 'app/cross-cutting-concerns/authentication/components/LoginHeader/LoginHeader';

export const TermsAndConditionsPage = (): JSX.Element => (
  <StyledTermsAndConditionsPage>
    <LoginHeader />
    <TermsAndConditions />
    <AppFooter />
  </StyledTermsAndConditionsPage>
);
