export class Scripts {
  public static addScriptTags(): void {
    Scripts.addUserSnapScriptTags();
    Scripts.addAdobeAnalyticsScriptTags();
  }

  /**
   * Adds a script tag to the document header containing the UserSnap script.
   * The script tag is only added if the REACT_APP_USER_SNAP_SCRIPT_URL environment variable is set.
   * The script tag is added to the header.
   */
  private static addUserSnapScriptTags(): void {
    const userSnapScriptUrl: string = process.env.REACT_APP_USER_SNAP_SCRIPT_URL ?? '';

    if (userSnapScriptUrl.trim().length <= 0) {
      return;
    }

    const headElement: HTMLHeadElement | null = document.querySelector('head');
    const headerScriptTag = document.createElement('script');
    headerScriptTag.setAttribute('src', userSnapScriptUrl);
    headerScriptTag.toggleAttribute('async');
    headElement?.appendChild(headerScriptTag);
  }

  private static addAdobeAnalyticsScriptTags(): void {
    const adobeAnalyticsScriptUrl: string = process.env.REACT_APP_ADOBE_ANALYTICS_SCRIPT_URL ?? '';

    if (adobeAnalyticsScriptUrl.trim().length <= 0) {
      return;
    }

    const headElement: HTMLHeadElement | null = document.querySelector('head');
    const headerScriptTag = document.createElement('script');
    headerScriptTag.setAttribute('src', adobeAnalyticsScriptUrl);
    headerScriptTag.toggleAttribute('async');
    headElement?.appendChild(headerScriptTag);

    const bodyElement: HTMLBodyElement | null = document.querySelector('body');
    const footerScriptTag = document.createElement('script');
    const footerSnippet = `_satellite.pageBottom();`;
    footerScriptTag.setAttribute('type', 'text/javascript');
    footerScriptTag.toggleAttribute('async');
    footerScriptTag.innerHTML = footerSnippet;
    bodyElement?.appendChild(footerScriptTag);
  }
}
