import { IAppConfiguration } from '../app/cross-cutting-concerns/configuration/interfaces/IAppConfiguration';

export const appConfig: IAppConfiguration = {
  service: {
    'en-US': {
      contactPerson: {
        employeeName: 'Fabian Helmut-Schipp',
        email: 'Fabian.helmut-schipp@kaercher.com',
        phoneNumber: '+49 1234 5516431',
      },
    },
    'de-DE': {
      contactPerson: {
        employeeName: 'Fabian Helmut-Schipp',
        email: 'Fabian.helmut-schipp@kaercher.com',
        phoneNumber: '+49 1234 5516431',
      },
    },
  },
  googleMaps: {
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY as string,
  },
};
