import { Action } from 'redux';
import { OperatorClientDeleteOptions } from '../../OperatorClient';
import { NotificationDeleteOperatorResponse } from '../../interfaces/Operator.types';
import { Optional } from 'lib/types/Optional';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';

// Operators
export interface IShowDeleteOperatorModalOptions {
  id: string;
}
export type IShowDeleteOperatorModalAction = Action<typeof UserModalsActions.SHOW_DELETE_OPERATOR_MODAL> & {
  payload: {
    id: string;
  };
};

export type IHideDeleteOperatorModalAction = Action<typeof UserModalsActions.HIDE_DELETE_OPERATOR_MODAL>;
export type IDeleteOperatorModalIsLoadingAction = Action<typeof UserModalsActions.DELETE_USER_MODAL_IS_LOADING>;
export type IDeleteOperatorModalIsNotLoadingAction = Action<typeof UserModalsActions.DELETE_USER_MODAL_IS_NOT_LOADING>;

export type IDeleteOperatorRequestOptions = OperatorClientDeleteOptions;
export type IDeleteOperatorRequestAction = Action<typeof UserModalsActions.DELETE_OPERATOR_REQUEST> & {
  payload: OperatorClientDeleteOptions;
};

export type IDeleteOperatorSuccessOptions = Optional<NotificationDeleteOperatorResponse>;
export type IDeleteOperatorSuccessAction = Action<typeof UserModalsActions.DELETE_OPERATOR_SUCCESS> & {
  payload: Optional<NotificationDeleteOperatorResponse>;
};

export type IDeleteOperatorErrorAction = IErrorAction<typeof UserModalsActions.DELETE_OPERATOR_ERROR>;

export class UserModalsActions {
  public static SHOW_DELETE_OPERATOR_MODAL = 'USER_MODALS::SHOW_DELETE_OPERATOR_MODAL';
  public static HIDE_DELETE_OPERATOR_MODAL = 'USER_MODALS::HIDE_DELETE_OPERATOR_MODAL';
  public static DELETE_USER_MODAL_IS_LOADING = 'USER_MODALS::DELETE_USER_MODAL_IS_LOADING';
  public static DELETE_USER_MODAL_IS_NOT_LOADING = 'USER_MODALS::DELETE_USER_MODAL_IS_NOT_LOADING';

  public static DELETE_OPERATOR_REQUEST = 'USER_MODALS::DELETE_OPERATOR_REQUEST';
  public static DELETE_OPERATOR_SUCCESS = 'USER_MODALS::DELETE_OPERATOR_SUCCESS';
  public static DELETE_OPERATOR_ERROR = 'USER_MODALS::DELETE_OPERATOR_ERROR';

  public static showDeleteOperatorModal({ id }: IShowDeleteOperatorModalOptions): IShowDeleteOperatorModalAction {
    return {
      type: UserModalsActions.SHOW_DELETE_OPERATOR_MODAL,
      payload: {
        id,
      },
    };
  }

  public static hideDeleteOperatorModal(): IHideDeleteOperatorModalAction {
    return {
      type: UserModalsActions.HIDE_DELETE_OPERATOR_MODAL,
    };
  }

  public static deleteOperatorModalIsLoading(): IDeleteOperatorModalIsLoadingAction {
    return {
      type: UserModalsActions.DELETE_USER_MODAL_IS_LOADING,
    };
  }

  public static deleteOperatorModalIsNotLoading(): IDeleteOperatorModalIsNotLoadingAction {
    return {
      type: UserModalsActions.DELETE_USER_MODAL_IS_NOT_LOADING,
    };
  }

  public static deleteOperatorRequest({ id }: IDeleteOperatorRequestOptions): IDeleteOperatorRequestAction {
    return {
      type: UserModalsActions.DELETE_OPERATOR_REQUEST,
      payload: {
        id,
      },
    };
  }

  public static deleteOperatorSuccess(data: IDeleteOperatorSuccessOptions): IDeleteOperatorSuccessAction {
    return {
      type: UserModalsActions.DELETE_OPERATOR_SUCCESS,
      payload: data,
    };
  }

  public static deleteOperatorError({ error }: IActionCreatorErrorOptions): IDeleteOperatorErrorAction {
    return {
      type: UserModalsActions.DELETE_OPERATOR_ERROR,
      error,
    };
  }
}
