import { Numbers } from '../../../lib/utils/number-formatting/Numbers';

export class ConvertUnitUtils {
  public static covertSquareMetersToSquareFeet(squareMeters: number): number {
    const result = Numbers.formatNumberDecimalRounded(squareMeters * 10.7639, 1);
    return result;
  }

  public static converMetersToMiles(meters: number): number {
    const result = Numbers.formatNumberDecimalRounded(meters * 0.000621371, 4);
    return result;
  }

  public static convertMetersToKilometers(meters: number): number {
    const result = Numbers.formatNumberDecimalRounded(meters / 1000, 1);
    return result;
  }

  public static convertMetersToFeetFactor(meters: number): number {
    const result = Numbers.formatNumberDecimalRounded(meters * 3.28084, 1);
    return result;
  }

  public static convertLitersToGallons(liters: number): number {
    const result = Numbers.formatNumberDecimalRounded(liters * 0.264172, 1);
    return result;
  }

  public static convertLiterToFluidOunces(liters: number): number {
    const result = Numbers.formatNumberDecimalRounded(liters * 33.814, 1);
    return result;
  }
}
