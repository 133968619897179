import styled from 'styled-components';
import { Modal } from 'lib/components/Modal/Modal';

export const StyledDeleteUserModal = styled(Modal)`
  .delete-user-modal__user-name {
    ${(props): string => props.theme.fontStyles.redesign.sm.strong}
    font-weight: ${(props): number => props.theme.fontWeights.p1Bold};
    margin-top: ${(props): string => props.theme.spacing.xxs};
  }

  .delete-user-modal__description {
    ${(props): string => props.theme.fontStyles.redesign.sm.normal}
  }
`;
