import styled from 'styled-components';

export const StyledInviteUserFormWrapper = styled.div`
  height: 100%;

  .invite-user-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .invite-user-form__description {
    ${(props): string => props.theme.fontStyles.p1.news};
    margin-bottom: 24px;
  }

  .ant-form-item-explain-error {
    color: ${(props): string => props.theme.colors.semanticError};
  }

  .invite-user-form__search-input-wrapper {
    padding: 8px;
  }

  .ant-select-dropdown-placement-topLeft .invite-user-form__search-input-wrapper {
    padding: 8px 16px;
    .invite-user-form__search-input {
      padding: 0 0 0 10px !important;
    }
  }

  .ant-empty-small {
    margin: 0;
    padding: 8px;
  }

  .invite-user-form__search-input {
    padding: 0 ${(props): string => props.theme.spacing.xs};

    &.ant-input-affix-wrapper-status-error {
      background: unset !important;
      border-color: ${(props): string => props.theme.colors.darkGrey} !important;

      .ant-input-prefix {
        color: unset;
      }
    }
  }

  .invite-user-form__actions {
    display: flex;
    justify-content: space-between;

    .submit-button,
    .cancel-button {
      font-family: ${(props): string => props.theme.fonts.redesign.lg.strong} !important;
      font-size: ${(props): string => props.theme.fontSizes.redesign.lg} !important;
      line-height: ${(props): string => props.theme.lineHeights.redesign.lg} !important;
      font-weight: ${(props): number => props.theme.fontWeights.redesign.lg} !important;
      padding: 8px 16px !important;
      height: 40px !important;
    }
    .cancel-button {
      border: 2px solid ${(props): string => props.theme.colors.gray100} !important;
    }
    .submit-button {
      border: none !important;
    }
  }

  .invite-user-form__input-group {
    margin-bottom: 16px;
    .ant-form-item-control-input {
      min-height: 32px;
    }
  }

  .ant-form-item-label {
    ${(props): string => props.theme.fontStyles.redesign.sm.delete}
    padding: 0;
  }

  .invite-user-form__select {
    display: flex;
    align-items: center;
    .ant-select-selector {
      width: 100%;
    }
    .ant-select-arrow {
      margin-top: -5px !important;
    }
  }
`;
