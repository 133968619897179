import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import * as UserListSelectors from '../../../../state/UserListSelectors';
import { StyledAddOperatorForm } from './AddOperatorForm.styles';
import * as authenticationSelectors from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { Checkbox } from 'lib/components/Checkbox/Checkbox';
import { AnalyticsForm, AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { supportedLanguages } from 'app/cross-cutting-concerns/translations/Translations';
import { Input } from 'lib/components/Input/Input';
import { Select } from 'lib/components/Select/Select';
import { FeatureFlagSelectors } from 'app/cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { Optional } from 'lib/types/Optional';
import { useAnalyticsSetForm } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import {
  AssignedMachineInput,
  NotificationOperator,
  SortOrders,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { OperatorFormValues } from 'app/modules/user-management/interfaces/Operator.types';
import { OpenSearch } from 'config/constants';
import { UserListActions } from 'app/modules/user-management/user-list/state/userListActions';
import { countries } from 'app/modules/user-management/country-data/countryData';

const languageOptions = Object.entries(supportedLanguages).map(([key, values]) => ({
  value: key === 'en-US' ? 'en' : key,
  content: values.name,
}));

export const AddOperatorForm = (): JSX.Element => {
  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formInstance] = Form.useForm();

  const hasAccessToRobots = useSelector(authenticationSelectors.selectHasAccessToRobots);

  const isLoading = useSelector(UserListSelectors.selectOperatorFormIsLoading);
  const availableMachines = useSelector(UserListSelectors.selectOperatorFormMachines) || [];
  const isAvailableMachinesLoading = useSelector(UserListSelectors.selectOperatorFormMachinesAreLoading);

  const isMachineReminderListEnabled = features.MACHINE_REMINDER_LIST;
  const isRobotIntegrationEnabled = features.ROBOT_INTEGRATION;
  const showRobotCategories = isRobotIntegrationEnabled && hasAccessToRobots;

  const isBatteryDeepChargeEnabled = features.BATTERY_DEEP_DISCHARGE;

  const [fields, setFields] = useState<Optional<NotificationOperator>>(undefined);
  const [activeAssignedMachines, setActiveAssignedMachines] = useState<string[]>(['']);
  const [isMachinesSelectOpen, setIsMachinesSelectOpen] = useState(false);

  const defaultCountry = countries.find(country => country.countryCode === 'DE');
  const [isSelectCountryCode, setIsSelectCountryCode] = useState<string>(defaultCountry?.countryCode || '');
  const [isSelectCountryDialCode, setIsSelectCountryDialCode] = useState<string>(defaultCountry?.phoneCode || '');

  const [isSelectNotiByEmail, setIsSelectNotiByEmail] = useState(false);
  const [isSelectNotiBySMS, setIsSelectNotiBySMS] = useState(false);
  const [isValidateNotiOptions, setIsValidateNotiOptions] = useState(false);

  const handleSelectNoti = (e: CheckboxChangeEvent): void => {
    const value = e.target.value;
    if (value === 'byEmail') {
      setIsSelectNotiByEmail(e.target.checked);
    } else {
      setIsSelectNotiBySMS(e.target.checked);
    }

    formInstance.validateFields(['notiOptions']);
  };

  const handleCountryChange = (value: string): void => {
    const dialCode = countries.find(country => country.countryCode === value)?.phoneCode;
    if (!dialCode) return;
    setIsSelectCountryDialCode(dialCode);
    setIsSelectCountryCode(value);
  };

  const notificationCats = [
    {
      name: 'notifyLocationStatus',
      label: 'userList.operatorForm.form.locationStatus',
      isRecommended: false,
      visible: true,
    },
    {
      name: 'notifyResources',
      label: 'userList.operatorForm.form.resources',
      isRecommended: true,
      visible: showRobotCategories,
    },
    {
      name: 'notifyCleaningTaskInterrupted',
      label: 'userList.operatorForm.form.cleaningTaskInterrupted',
      isRecommended: true,
      visible: showRobotCategories,
    },
    {
      name: 'notifyCleaningTaskFinished',
      label: 'userList.operatorForm.form.cleaningTaskFinished',
      isRecommended: true,
      visible: showRobotCategories,
    },
    {
      name: 'notifyEmergencyStop',
      label: 'userList.operatorForm.form.emergencyStop',
      isRecommended: true,
      visible: showRobotCategories,
    },
    {
      name: 'notifyErrorOccurred',
      label: 'userList.operatorForm.form.errorOccurred',
      isRecommended: true,
      visible: showRobotCategories,
    },
    {
      name: 'notifyInformation',
      label: 'userList.operatorForm.form.information',
      isRecommended: true,
      visible: showRobotCategories,
    },
    {
      name: 'notifyServiceNeeded',
      label: 'userList.operatorForm.form.serviceNeeded',
      isRecommended: true,
      visible: showRobotCategories,
    },
    {
      name: 'notifyReminder',
      label: 'userList.operatorForm.form.reminder',
      isRecommended: false,
      visible: isMachineReminderListEnabled,
    },
    {
      name: 'notifyNoWorkStart',
      label: 'userList.operatorForm.form.noWorkStart',
      isRecommended: false,
      visible: true,
    },
    {
      name: 'notifyPCM',
      label: 'userList.operatorForm.form.pcm',
      isRecommended: false,
      visible: true,
    },
    {
      name: 'notifyBatteryDeepDischarge',
      label: 'userList.operatorForm.form.batteryDeepDischarge',
      isRecommended: true,
      visible: isBatteryDeepChargeEnabled,
    },
  ];

  const handleValuesChange = (): void => {
    setFields(formInstance.getFieldsValue());
  };

  const handleSubmit = useCallback(() => {
    setFields(formInstance.getFieldsValue());
    formInstance.submit();
  }, [formInstance]);

  const handleCancel = useCallback(() => {
    dispatch(UserListActions.hideUserManagementDrawer());
  }, [dispatch]);

  const onFinish = (values: OperatorFormValues): void => {
    let notifyReminder = values?.notifyReminder ?? false;
    const notifyEmergencyStop = values?.notifyEmergencyStop ?? false;
    const notifyResources = values?.notifyResources ?? false;
    const notifyErrorOccurred = values?.notifyErrorOccurred ?? false;
    const notifyAutonomousTaskStopped = values?.notifyAutonomousTaskStopped ?? false;
    const notifyCleaningTaskInterrupted = values?.notifyCleaningTaskInterrupted ?? false;
    const notifyCleaningTaskFinished = values?.notifyCleaningTaskFinished ?? false;
    const notifyLocationStatus = values?.notifyLocationStatus ?? false;
    const notifyNoWorkStart = values?.notifyNoWorkStart ?? false;
    const notifyOther = values?.notifyOther ?? false;
    const notifyInformation = values?.notifyInformation ?? false;
    const notifyServiceNeeded = values?.notifyServiceNeeded ?? false;
    const notifyPCM = values?.notifyPCM ?? false;
    let notifyBatteryDeepDischarge = values?.notifyBatteryDeepDischarge ?? false;

    let phoneInput = values.phoneNumber || '';
    if (phoneInput.startsWith('0')) {
      phoneInput = phoneInput.substring(1);
    }

    if (isMachineReminderListEnabled) {
      notifyReminder = values.notifyReminder || false;
    }

    if (isBatteryDeepChargeEnabled) {
      notifyBatteryDeepDischarge = values.notifyBatteryDeepDischarge || false;
    }

    analyticsLinkActivated({
      linkName: AnalyticsLink.UPSERT_OPERATOR,
    });

    let assignedMachines = availableMachines.map(machine => ({
      id: machine.id,
      name: machine.name,
    })) as AssignedMachineInput[];

    if (values.assignedMachines[0] !== '') {
      assignedMachines = availableMachines
        .filter(machine => values.assignedMachines?.includes(machine.id))
        .map(machine => ({
          id: machine.id,
          name: machine.name,
        }));
    }

    dispatch(
      UserListActions.saveOperatorRequest({
        input: {
          name: values?.name?.trim() as string,
          phoneNumber: isSelectNotiBySMS ? isSelectCountryDialCode + phoneInput.replaceAll(' ', '') : undefined,
          countryCode: isSelectNotiBySMS ? isSelectCountryCode : undefined,
          email: isSelectNotiByEmail ? values?.email?.trim() : undefined,
          assignedMachines,
          language: values?.language || 'en',
          notifications: {
            notifyAutonomousTaskStopped,
            notifyCleaningTaskFinished,
            notifyCleaningTaskInterrupted,
            notifyEmergencyStop,
            notifyErrorOccurred,
            notifyInformation,
            notifyLocationStatus,
            notifyNoWorkStart,
            notifyPCM,
            notifyOther,
            notifyReminder,
            notifyResources,
            notifyServiceNeeded,
            notifyBatteryDeepDischarge,
          },
        },
      })
    );
  };

  const handleMachinesChange = (values: SelectValue): void => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.OPERATOR_ASSIGNED_MACHINES,
    });

    setActiveAssignedMachines(values as string[]);

    formInstance.setFieldsValue({
      assignedMachines: values,
    });
  };

  const handleMachineSelectVisible = (open: boolean): void => {
    setIsMachinesSelectOpen(open);
  };

  useAnalyticsSetForm({
    name: AnalyticsForm.UPSERT_OPERATOR,
    fields: fields as Record<string, any>,
    isVisible: true,
  });

  useEffect(() => {
    dispatch(
      UserListActions.getMachineListWithoutImageRequest({
        paginationOptions: {
          limit: OpenSearch.MAX_RESULT_WINDOW,
        },
        sortOptions: {
          field: 'name',
          order: SortOrders.Asc,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(
    () => (): void => {
      dispatch(UserListActions.resetMachinesState());
      setIsSelectCountryCode(defaultCountry?.countryCode || '');
      setIsSelectCountryDialCode(defaultCountry?.phoneCode || '');
    },
    [dispatch, defaultCountry]
  );

  return (
    <StyledAddOperatorForm>
      <Form
        className="operator-form"
        form={formInstance}
        name="add-operator"
        layout="vertical"
        autoComplete="off"
        onValuesChange={handleValuesChange}
        onFinish={onFinish}
        initialValues={{
          language: languageOptions[0].value,
          countryCode: defaultCountry?.countryCode || '',
        }}
      >
        <div className="operator-form__content">
          <Form.Item
            name="name"
            label={t('userList.operatorForm.form.name')}
            className="operator-form__info-user__input-group"
            required
            rules={[{ required: true, message: t('userList.operatorForm.form.errors.nameRequired') }]}
          >
            <Input className="operator-form__info-user__input" />
          </Form.Item>

          <Form.Item
            name="language"
            label={t('userList.operatorForm.form.language')}
            className="operator-form__info-user__input-group"
          >
            <Select
              dropdownVisibleState
              selectedOptionsOnTop
              className="operator-form__select"
              options={languageOptions.map(({ value, content }) => ({
                label: t(content),
                value,
              }))}
              onReset={(): void => {
                formInstance.setFieldsValue({
                  language: languageOptions[0].value,
                });
              }}
            />
          </Form.Item>

          {isSelectNotiByEmail && (
            <Form.Item
              name="email"
              label={t('userList.operatorForm.form.email')}
              className="operator-form__info-user__input-group"
              rules={[
                { required: true, message: t('userList.operatorForm.form.errors.emailRequired') },
                { type: 'email', message: t('userList.operatorForm.form.errors.emailInvalid') },
              ]}
            >
              <Input className="operator-form__info-user__input" />
            </Form.Item>
          )}

          {isSelectNotiBySMS && (
            <Form.Item
              label={t('userList.operatorForm.form.phoneNumber')}
              className="operator-form__info-user__input-group"
            >
              <Row>
                <Form.Item
                  name="countryCode"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: t('userList.operatorForm.form.errors.countryCodeRequired'),
                    },
                  ]}
                >
                  <Select
                    dropdownVisibleState
                    className="operator-form__select-phone-code"
                    options={countries.map(country => ({
                      label: (
                        <div className="operator-form__select-label">
                          {country.flag && (
                            <country.flag style={{ height: '22px', width: '24px', marginRight: '8px' }} />
                          )}

                          <p style={{ marginTop: '12px' }}>
                            {country.countryCode} ({country.phoneCode})
                          </p>
                        </div>
                      ),
                      value: country.countryCode,
                    }))}
                    onChange={handleCountryChange}
                    defaultValue={defaultCountry?.countryCode}
                    filterOption={(input, option): boolean => {
                      if (!option || option.value == null || option.label == null) return false;
                      const label = option.label as React.ReactElement;
                      const inputValue = input.toLowerCase();
                      const labelTextArray = label.props.children[1].props.children || '';
                      return labelTextArray.toString().toLowerCase().includes(inputValue);
                    }}
                    onClear={(): void => {
                      setIsSelectCountryCode(defaultCountry?.countryCode || '');
                      setIsSelectCountryDialCode(defaultCountry?.phoneCode || '');
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="phoneNumber"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: t('userList.operatorForm.form.errors.phoneNumberRequired'),
                    },
                    {
                      pattern: /^\d{9,12}$/,
                      message: t('userList.operatorForm.form.phoneNumberInvalid'),
                    },
                  ]}
                >
                  <Input className="operator-form__info-user__input-phone-code" />
                </Form.Item>
              </Row>
            </Form.Item>
          )}

          <Form.Item
            name="notiOptions"
            label={t('userList.operatorForm.form.notificationOptions.title')}
            className="operator-form__checkbox"
            rules={[
              {
                validator: (): Promise<void> => {
                  if (!isSelectNotiByEmail && !isSelectNotiBySMS) {
                    setIsValidateNotiOptions(true);
                    return Promise.reject(
                      new Error(t('userList.operatorForm.form.errors.notificationOptionsRequired'))
                    );
                  }
                  setIsValidateNotiOptions(false);
                  return Promise.resolve();
                },
              },
            ]}
            required
            validateTrigger="onChange"
          >
            <Col span={24}>
              <Checkbox
                className={`operator-form__checkbox-label ${isValidateNotiOptions ? 'has-error' : ''}`}
                onChange={handleSelectNoti}
                value="byEmail"
                checked={isSelectNotiByEmail}
              >
                {t('userList.operatorForm.form.notificationOptions.byEmail')}
              </Checkbox>
              <Checkbox
                className={`operator-form__checkbox-label ${isValidateNotiOptions ? 'has-error' : ''}`}
                onChange={handleSelectNoti}
                value="bySMS"
                checked={isSelectNotiBySMS}
              >
                {t('userList.operatorForm.form.notificationOptions.bySMS')}
              </Checkbox>
            </Col>
          </Form.Item>

          <Form.Item
            name="assignedMachines"
            label={t('userList.operatorForm.form.assignedDevices')}
            className="operator-form__info-user__input-group"
            required
            rules={[{ required: true, message: t('userList.operatorForm.form.errors.assignedDevicesRequired') }]}
          >
            <Select
              dropdownVisibleState
              selectedOptionsOnTop
              className="operator-form__info-user__selector"
              mode="multiple"
              loading={!!isAvailableMachinesLoading}
              onChange={handleMachinesChange}
              value={activeAssignedMachines}
              onDropdownVisibleChange={handleMachineSelectVisible}
              tagRender={({ label, value }): React.ReactElement => {
                if (isMachinesSelectOpen) return <></>;

                if (activeAssignedMachines?.length === 1) {
                  return <>{label}</>;
                }

                return <>{activeAssignedMachines?.[0] === value ? t('common.multiple') : null}</>;
              }}
              options={[
                ...(availableMachines?.map(machine => ({
                  label: machine.name,
                  value: machine.id,
                })) || []),
              ]}
            />
          </Form.Item>

          <div className="operator-form__checkbox-group">
            <Col span={24}>
              <div className="operator-form__note">{t('userList.operatorForm.note')}</div>
              {showRobotCategories && (
                <div className="operator-form__recommended">&#42; {t('userList.operatorForm.recommended')}</div>
              )}
            </Col>
            <Row className="operator-form__checkbox-row">
              {notificationCats &&
                notificationCats.map(notificationCat => {
                  if (!notificationCat.visible) return <></>;
                  return (
                    <Col span={12} key={notificationCat.name}>
                      <Form.Item
                        name={notificationCat.name}
                        className="operator-form__checkbox"
                        valuePropName="checked"
                      >
                        <Checkbox className="operator-form__checkbox-label">
                          {t(notificationCat.label)} {notificationCat.isRecommended && <span>&#42;</span>}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  );
                })}
            </Row>
          </div>
        </div>
        <div className="operator-form__actions">
          <SecondaryButton size="m" onClick={handleCancel} className="cancel-button" key="cancel-button">
            {t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton
            size="m"
            key="submit"
            className="submit-button"
            type="primary"
            onClick={handleSubmit}
            loading={isLoading}
          >
            {t('common.add')}
          </PrimaryButton>
        </div>
      </Form>
    </StyledAddOperatorForm>
  );
};
