import styled from 'styled-components';

export const StyledUserList = styled.div`
  ${(props): string => props.theme.mixins.layout.addHeaderContentClass('user-list')}
  ${(props): string => props.theme.mixins.layout.addBodyContentClass('user-list')}
  ${(props): string => props.theme.mixins.layout.addContainerClasses('user-list')}

  min-height: inherit;

  .user-list__header {
    background-color: transparent;
  }

  .user-list__page-info {
    display: flex;
    justify-content: space-between;
  }

  .user-list__title {
    ${(props): string => props.theme.fontStyles.redesign.heading1};
    color: ${(props): string => props.theme.colors.black};
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  .user-list__invite-user-button__icon {
    vertical-align: sub;
    margin-right: 4px;
  }

  .user-list__select {
    width: 100%;
  }

  .user-list__body-content {
    padding-top: 0;
  }

  .user-list__body-controls {
    display: flex;
    justify-content: flex-start;
    align-items: end;
    margin: 22px 0;
  }

  .user-list__search-input {
    width: 320px;
    margin-left: auto;

    .ant-input-suffix {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      border-left: 1px solid ${(props): string => props.theme.colors.darkGrey};
      padding: 14px;
    }

    .user-list__search-bar-icon {
      width: 16px;
      height: 16px;
      i,
      svg {
        width: 16px;
        height: 16px;
      }
    }
    .ant-input-affix-wrapper {
      padding: 0 0 0 8px;
    }
  }

  .user-list__tabs {
    .ant-tabs-content-holder {
      display: none;
    }

    .ant-tabs-tab {
      ${(props): string => props.theme.fontStyles.redesign.base.normal};
    }

    .ant-tabs-tab-active {
      ${(props): string => props.theme.fontStyles.redesign.base.strong};
    }
  }

  .user-list__filters {
    display: flex;
  }

  .user-list__select-wrapper {
    margin-right: 24px;
    width: 200px;
  }

  .user-list__date-range-picker-wrapper {
    display: grid;
    height: 54px;
  }

  .user-list__filter-label {
    ${(props): string => props.theme.fontStyles.redesign.base.normal};
  }
`;
