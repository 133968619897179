import React, { ReactElement } from 'react';
import { ReactComponent as Events } from '../app/assets/images/events.svg';
import { ReactComponent as UserSettings } from '../app/assets/images/userSettings.svg';
import { ReactComponent as Home } from '../app/assets/images/home.svg';
import { ReactComponent as Machines } from '../app/assets/images/machines.svg';
import { ReactComponent as RobotDashboard } from '../app/assets/images/robot-dashboard.svg';
import { ReactComponent as Sites } from '../app/assets/images/sites.svg';
import { ReactComponent as Service } from '../app/assets/images/service.svg';
import { ReactComponent as NotificationIcon } from '../app/assets/images/notifications.svg';
import { RoutePaths } from './route-paths';

export interface MenuItem {
  path: string;
  translationKey: string;
  icon: ReactElement;
}

export const menuItems = [
  { translationKey: 'common.overview', path: RoutePaths.OVERVIEW, icon: <Home /> },
  { translationKey: 'common.robotDashboard', path: RoutePaths.ROBOT_DASHBOARD, icon: <RobotDashboard /> },
  { translationKey: 'common.devices', path: RoutePaths.MACHINES, icon: <Machines /> },
  { translationKey: 'common.sites', path: RoutePaths.SITES, icon: <Sites /> },
  { translationKey: 'common.events', path: RoutePaths.EVENTS, icon: <Events /> },
  { translationKey: 'common.notifications', path: RoutePaths.NOTIFICATIONS, icon: <NotificationIcon /> },
  { translationKey: 'common.service', path: RoutePaths.SERVICE, icon: <Service /> },
];

export const footerMenuItems = [
  { translationKey: 'common.userManagement', path: RoutePaths.USERS, icon: <UserSettings /> },
];
