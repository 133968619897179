import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { UserListActions } from '../../../state/userListActions';
import { AddOperatorForm } from './AddOperatorsForm/AddOperatorForm';
import { InviteUserForm } from './InviteUserForm/InviteUserForm';
import { StyledUserManagementDrawer } from './UserManagementDrawer.styles';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { FeatureFlagSelectors } from 'app/cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { selectHasAccessToRobots } from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { Tabs } from 'lib/components/Tabs/Tabs';
import * as UserListSelectors from 'app/modules/user-management/user-list/state/UserListSelectors';

export enum InviteUserTab {
  INVITE_USER = 'INVITE_USER',
  ADD_OPERATOR = 'ADD_OPERATOR',
}

export const UserManagementDrawer = (): JSX.Element => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const [activeTab, setActiveTab] = useState(InviteUserTab.INVITE_USER);
  const isUserManagementDrawerVisible = useSelector(UserListSelectors.selectUserManagementDrawerVisible);

  const isRobotIntegrationEnabled = features.ROBOT_INTEGRATION;
  const haveRobots = useSelector(selectHasAccessToRobots);
  const isCustomerInviteEnabled = features.CUSTOMER_INVITE;
  const isShowAddOperator = isRobotIntegrationEnabled && haveRobots;

  const handleTabChange = (activeTabKey: string): void => {
    setActiveTab(activeTabKey as InviteUserTab);

    if (activeTabKey === InviteUserTab.ADD_OPERATOR) {
      analyticsLinkActivated({
        linkName: AnalyticsLink.UPSERT_OPERATOR,
      });
    } else {
      analyticsLinkActivated({
        linkName: AnalyticsLink.INVITE_USER,
      });
    }
  };

  const hideUserManagementDrawer = (): void => {
    dispatch(UserListActions.hideUserManagementDrawer());
  };

  const tabs = [];
  if (isCustomerInviteEnabled) {
    tabs.push({
      label: t('userList.inviteNewUser'),
      key: InviteUserTab.INVITE_USER,
    });
  }

  if (isShowAddOperator) {
    tabs.push({
      label: t('userList.addOperator'),
      key: InviteUserTab.ADD_OPERATOR,
    });
  }

  return (
    <StyledUserManagementDrawer
      className="user-management-panel"
      title={
        isShowAddOperator ? t('userList.userManagementPanel.title') : t('userList.userManagementPanel.titleAddUser')
      }
      size="small"
      open={isUserManagementDrawerVisible}
      onClose={hideUserManagementDrawer}
    >
      <Row gutter={24}>
        <Col span={24}>
          <div className="user-management-panel__description">
            {isShowAddOperator
              ? t('userList.userManagementPanel.description')
              : t('userList.userManagementPanel.descriptionAddUser')}
          </div>
        </Col>
      </Row>
      {isShowAddOperator ? (
        <Tabs destroyInactiveTabPane={true} items={tabs} onChange={handleTabChange} activeKey={activeTab} />
      ) : (
        <div className="user-management-panel__spacing"></div>
      )}
      <div className="user-management-panel__body">
        {activeTab === InviteUserTab.INVITE_USER && <InviteUserForm />}
        {activeTab === InviteUserTab.ADD_OPERATOR && <AddOperatorForm />}
      </div>
    </StyledUserManagementDrawer>
  );
};
