import { chunk } from 'lodash-es';

export interface ReactChartsData {
  label: string;
  data: ReactChartsDatum[];
}

export interface ReactChartsDatum {
  primary: string;
  secondary: number;
}

interface OperatingHoursData {
  id: string;
  name: string;
  operatingTimeForPeriod: {
    actualTotalOperatingTimeMs: number;
    plannedTotalOperatingTimeMs: number;
  };
}

export const NUM_CHART_COLUMNS = 10;
export const ACTUAL_CHART_COLUMN_LABEL = 'operatingHoursComparisonChart.actual';
export const PLANNED_CHART_COLUMN_LABEL = 'operatingHoursComparisonChart.planned';

export class ReactChartUtils {
  public static combineColumnName = (text1?: string, text2?: string): string =>
    `${text1 || ''}${this.delimiterName}${text2 || ''}`;

  public static extractColumnName = (text?: string): string => (text ? text.split(this.delimiterName)[0] || '' : '');

  public static convertOperatingHoursToChartData(operatingHoursData: OperatingHoursData[]): ReactChartsData[] {
    const data: ReactChartsData[] = [
      { label: ACTUAL_CHART_COLUMN_LABEL, data: [] },
      { label: PLANNED_CHART_COLUMN_LABEL, data: [] },
    ];
    if (operatingHoursData.length === 0) {
      data[0].data.push({ primary: '-', secondary: 0 });
      data[1].data.push({ primary: '-', secondary: 0 });
      return data;
    }

    operatingHoursData.forEach(({ id, name, operatingTimeForPeriod }) => {
      const { actualTotalOperatingTimeMs, plannedTotalOperatingTimeMs } = operatingTimeForPeriod;

      data[0].data.push({
        primary: ReactChartUtils.combineColumnName(name, id),
        secondary: actualTotalOperatingTimeMs,
      });

      data[1].data.push({
        primary: ReactChartUtils.combineColumnName(name, id),
        secondary: plannedTotalOperatingTimeMs,
      });
    });

    return data;
  }

  public static chunkChartData(data: ReactChartsData[], chunkSize = NUM_CHART_COLUMNS): ReactChartsData[][] {
    const chunkData: ReactChartsData[][] = [];
    const actualHrsData: ReactChartsDatum[][] = chunk(data[0].data, chunkSize);
    const plannedHrsData: ReactChartsDatum[][] = chunk(data[1].data, chunkSize);
    for (let index = 0; index < actualHrsData.length; index += 1) {
      chunkData.push([
        { label: data[0].label, data: actualHrsData[index] },
        { label: data[1].label, data: plannedHrsData[index] },
      ]);
    }
    return chunkData;
  }

  private static delimiterName = '::';
}
