import { useTranslation } from 'react-i18next';
import { ReactComponent as LetterUnread } from '../../../../../assets/images/letter-unread.svg';
import { StyledSentInvitationsBlank } from './SentInvitationsBlank.styles';

export const SentInvitationsBlank = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledSentInvitationsBlank className="sent-invitations-blank">
      <LetterUnread className="sent-invitations-blank__mail-icon" />
      <p>{t('userList.sentInvitations.noOpenUserInvitations')}</p>
    </StyledSentInvitationsBlank>
  );
};
