export class LegalInfoUtils {
  public static getImprintUrl(currentLocale: string): string {
    if (LegalInfoUtils.GERMAN_LANGUAGE_TAGS.includes(currentLocale)) {
      return LegalInfoUtils.IMPRINT_URL_BY_LOCALE['de-DE'];
    }

    return LegalInfoUtils.IMPRINT_URL_BY_LOCALE.international;
  }

  public static getPrivacyPolicyLanguage(_currentLocale: string): string {
    // TODO: Only german privacy policy is available to start with
    // Change back to commented out implementation once English is also available

    // if (LegalInfoUtils.GERMAN_LANGUAGE_TAGS.includes(currentLocale)) return currentLocale;
    //
    // return 'en-US';

    return 'de-DE';
  }

  /**
   * IETF language tags that either specify Germany in region subtag or don't specify a region subtag
   * This purposefully excludes region subtags for Austria, Liechtenstein, Luxembourg and Switzerland.
   */
  private static GERMAN_LANGUAGE_TAGS = ['de-DE', 'de'];

  private static IMPRINT_URL_BY_LOCALE = {
    international: 'https://www.kaercher.com/int/inside-kaercher/company/legal/imprint.html',
    'de-DE': 'https://www.kaercher.com/de/inside-kaercher/unternehmen/impressum.html',
  };
}
