import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalyticsSetPageInfo } from '../../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetPageInfo';
import { useAnalyticsAddUserAttributes } from '../../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsAddUserAttributes';
import { UserListActions } from '../../../state/userListActions';
import { UserListSelectors } from '../../../state/UserListSelectors';
import { StyledUserListOperator } from './UserListOperator.styles';
import { getUserListOperatorColumns } from './columns/UserListOperator.columns';
import { OperatorDetailsDrawer } from './OperatorDetailsDrawer/OperatorDetailsDrawer';
import { Table } from 'lib/components/Table/Table';
import { NotificationOperator } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';
import { InfiniteScrollConstants } from 'config/constants';

export const UserListOperator = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedRowKey, setSelectedRowKey] = useState<string>('');

  const data = useSelector(UserListSelectors.selectOperatorData) || [];
  const isLoading = !!useSelector(UserListSelectors.selectOperatorIsLoading);
  const totalCount = useSelector(UserListSelectors.selectOperatorTotalCount) || 0;
  const isLoadingMoreData = useSelector(UserListSelectors.selectOperatorIsLoadingMoreData);
  const nextPaginationToken = useSelector(UserListSelectors.selectOperatorNextPaginationToken);

  useAnalyticsAddUserAttributes({
    countAuthorized: totalCount,
  });
  useAnalyticsSetPageInfo({});

  useEffect(() => {
    dispatch(
      UserListActions.getUserListOperatorRequest({
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_ITEMS,
          paginationToken: '',
        },
      })
    );
  }, [dispatch]);

  const loadMore = (): void => {
    dispatch(
      UserListActions.getUserListOperatorMoreDataRequest({
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_ITEMS,
          paginationToken: nextPaginationToken,
        },
      })
    );
  };

  useEffect(
    () => (): void => {
      dispatch(UserListActions.resetState());
      dispatch(DrawersActions.hideOperatorDetailsDrawer());
    },
    [dispatch]
  );

  const tableColumns = getUserListOperatorColumns({
    t,
  });

  const openDrawer = (operator: NotificationOperator): void => {
    if (!operator.id) return;
    dispatch(UserListActions.getOperatorRequest({ id: operator.id }));
    dispatch(DrawersActions.showOperatorDetailsDrawer({ operatorId: operator.id }));
  };

  const rowClassName = (record: NotificationOperator): string =>
    record.id === selectedRowKey ? 'table__selected-row' : '';

  return (
    <>
      <StyledUserListOperator className="user-list-operator">
        <Table
          dataSource={data}
          loading={isLoading}
          className="user-list-operator__table"
          columns={tableColumns}
          rowKey="id"
          onRow={(operator: NotificationOperator): { onClick(): void } => ({
            onClick: (): void => {
              openDrawer(operator);
              // why id is optional ???
              setSelectedRowKey(operator.id || '');
            },
          })}
          rowClassName={rowClassName}
          infiniteScroll={{
            id: 'user-list-operator-infinite-scroll',
            next: loadMore,
            nextPaginationToken,
            isLoadingMoreData,
          }}
          showScrollButtons={true}
        />
        <OperatorDetailsDrawer />
      </StyledUserListOperator>
    </>
  );
};
