import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeleteUserModalVisible, selectDeleteUserModalIsLoading } from '../../state/userModalsSelectors';
import { userModalsActions } from '../../state/userModalsSlice';
import { StyledDeleteUserModal } from './DeleteUserModal.styles';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import * as UserModalsSelectors from 'app/modules/user-management/modals/state/userModalsSelectors';

interface DeleteUserModalProps {
  name: string;
}
export const DeleteUserModal = (props: DeleteUserModalProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isVisible = useSelector(selectDeleteUserModalVisible);
  const isLoading = useSelector(selectDeleteUserModalIsLoading);
  const email = useSelector(UserModalsSelectors.selectDeleteUserEmail);

  const handleCancel = useCallback(() => {
    dispatch(userModalsActions.hideDeleteUserModal());
  }, [dispatch]);

  const handleOk = useCallback(() => {
    dispatch(userModalsActions.deleteUserRequest({ input: { email } }));
  }, [dispatch, email]);

  return (
    <StyledDeleteUserModal
      className="delete-user-modal"
      title={t('userList.deleteUserModal.title')}
      centered
      open={isVisible}
      destroyOnClose={true}
      closable={false}
      footer={[
        <SecondaryButton
          size="m"
          disabled={isLoading}
          onClick={handleCancel}
          className="delete-user-modal__button"
          key="cancel-button"
        >
          {t('common.cancel')}
        </SecondaryButton>,
        <PrimaryButton
          size="m"
          key="submit"
          className="delete-user-modal__submit-button"
          type="primary"
          loading={isLoading}
          onClick={handleOk}
        >
          {t('userList.deleteUserModal.deleteBtn')}
        </PrimaryButton>,
      ]}
    >
      <div className="delete-user-modal__description">{t('userList.deleteUserModal.description')}</div>
      <div className="delete-user-modal__user-name">{props.name}</div>
    </StyledDeleteUserModal>
  );
};
