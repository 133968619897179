import { StyledLicenseAgreementPage } from './LicenseAgreementPage.styles';
import { AppFooter } from 'app/components/app-layout/AppFooter/AppFooter';
import { AppHeader } from 'app/components/app-layout/AppHeader/AppHeader';
import { LicenseAgreement } from 'app/modules/terms-conditions/components/LicenseAgreement/LicenseAgreement';

export const LicenseAgreementPage = (): JSX.Element => (
  <StyledLicenseAgreementPage>
    <AppHeader />
    <LicenseAgreement />
    <AppFooter />
  </StyledLicenseAgreementPage>
);
