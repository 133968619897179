import { TFunction } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { NotificationOperator } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

interface GetUserListOperatorColumnsOptions {
  t: TFunction;
}

export const getUserListOperatorColumns = ({
  t,
}: GetUserListOperatorColumnsOptions): ColumnsType<NotificationOperator> => [
  {
    title: t('userList.operator.table.operator'),
    dataIndex: ['name'],
    width: '25%',
    key: 'name',
  },
  {
    title: t('userList.operator.table.email'),
    dataIndex: ['email'],
    width: '25%',
    key: 'email',
    render: (email: string) => <span>{email || t('common.NA')}</span>,
  },
  {
    title: t('userList.operator.table.phoneNumber'),
    dataIndex: ['phoneNumber'],
    width: '22%',
    key: 'phoneNumber',
    render: (phoneNumber: string) => <span>{phoneNumber || t('common.NA')}</span>,
  },
  {
    title: t('userList.operator.table.language'),
    dataIndex: ['language'],
    width: '5%',
    key: 'language',
    render: (language: string) => <span>{t(`languages.${language}`)}</span>,
  },
  {
    title: t('userList.operator.table.machines'),
    dataIndex: ['numberOfAssignedMachines'],
    width: '5%',
    key: 'numberOfAssignedMachines',
    render: (numberOfAssignedMachines: number): React.ReactNode => <span>{numberOfAssignedMachines}</span>,
  },
];
