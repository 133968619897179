export const downloadLink = ({ fileUrl, fileName }: { fileUrl: string; fileName?: string }): void => {
  const link = document.createElement('a');
  link.setAttribute('href', fileUrl);

  if (fileName) {
    link.setAttribute('download', fileName);
  }

  link.click();
  link.remove();
};
