import { produce } from 'immer';
import { AnyAction } from 'redux';
import { UserModalsActions } from './userModalsActions';

export interface UserModalsState {
  operator: {
    operatorDelete: {
      visible: boolean;
      isLoading: boolean;
      operatorId: string;
    };
  };
}

export const initialState: UserModalsState = {
  operator: {
    operatorDelete: {
      visible: false,
      isLoading: false,
      operatorId: '',
    },
  },
};

export const userModalsReducer = (state = initialState, action: AnyAction): UserModalsState =>
  produce(state, draft => {
    switch (action.type) {
      case UserModalsActions.SHOW_DELETE_OPERATOR_MODAL: {
        draft.operator.operatorDelete.visible = true;
        draft.operator.operatorDelete.operatorId = action.payload.id;
        return draft;
      }
      case UserModalsActions.HIDE_DELETE_OPERATOR_MODAL: {
        draft.operator.operatorDelete.visible = false;
        draft.operator.operatorDelete.operatorId = initialState.operator.operatorDelete.operatorId;
        return draft;
      }
      case UserModalsActions.DELETE_USER_MODAL_IS_LOADING: {
        draft.operator.operatorDelete.isLoading = true;
        return draft;
      }
      case UserModalsActions.DELETE_USER_MODAL_IS_NOT_LOADING: {
        draft.operator.operatorDelete.isLoading = false;
        return draft;
      }

      case UserModalsActions.DELETE_OPERATOR_REQUEST: {
        return draft;
      }
      case UserModalsActions.DELETE_OPERATOR_SUCCESS: {
        return draft;
      }
      case UserModalsActions.DELETE_OPERATOR_ERROR: {
        return draft;
      }
      default:
        return draft;
    }
  });
