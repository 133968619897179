import styled from 'styled-components';

export const StyledSentInvitationsBlank = styled.div`
  min-height: 169px;
  border: 1px solid ${(props): string => props.theme.colors.brightGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .sent-invitations-blank__mail-icon {
    width: 60px;
    margin-bottom: 20px;
  }
`;
