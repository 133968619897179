import React from 'react';
import { ReactComponent as AustraliaFlag } from 'lib/assets/images/country-flags/Australia.svg';
import { ReactComponent as AustriaFlag } from 'lib/assets/images/country-flags/Austria.svg';
import { ReactComponent as BelgiumFlag } from 'lib/assets/images/country-flags/Belgium.svg';
import { ReactComponent as BrazilFlag } from 'lib/assets/images/country-flags/Brazil.svg';
import { ReactComponent as BulgariaFlag } from 'lib/assets/images/country-flags/Bulgaria.svg';
import { ReactComponent as CanadaFlag } from 'lib/assets/images/country-flags/Canada.svg';
import { ReactComponent as ChileFlag } from 'lib/assets/images/country-flags/Chile.svg';
import { ReactComponent as ChinaFlag } from 'lib/assets/images/country-flags/China.svg';
import { ReactComponent as ColombiaFlag } from 'lib/assets/images/country-flags/Colombia.svg';
import { ReactComponent as CroatiaFlag } from 'lib/assets/images/country-flags/Croatia.svg';
import { ReactComponent as DenmarkFlag } from 'lib/assets/images/country-flags/Denmark.svg';
import { ReactComponent as DubaiFlag } from 'lib/assets/images/country-flags/Dubai.svg';
import { ReactComponent as EstoniaFlag } from 'lib/assets/images/country-flags/Estonia.svg';
import { ReactComponent as FinlandFlag } from 'lib/assets/images/country-flags/Finland.svg';
import { ReactComponent as FranceFlag } from 'lib/assets/images/country-flags/France.svg';
import { ReactComponent as GermanyFlag } from 'lib/assets/images/country-flags/Germany.svg';
import { ReactComponent as GreatBritainFlag } from 'lib/assets/images/country-flags/GreatBritain.svg';
import { ReactComponent as GreeceFlag } from 'lib/assets/images/country-flags/Greece.svg';
import { ReactComponent as HongKongFlag } from 'lib/assets/images/country-flags/HongKong.svg';
import { ReactComponent as HungaryFlag } from 'lib/assets/images/country-flags/Hungary.svg';
import { ReactComponent as IndiaFlag } from 'lib/assets/images/country-flags/India.svg';
import { ReactComponent as IndonesiaFlag } from 'lib/assets/images/country-flags/Indonesia.svg';
import { ReactComponent as IrelandFlag } from 'lib/assets/images/country-flags/Ireland.svg';
import { ReactComponent as ItalyFlag } from 'lib/assets/images/country-flags/Italy.svg';
import { ReactComponent as JapanFlag } from 'lib/assets/images/country-flags/Japan.svg';
import { ReactComponent as KazakhstanFlag } from 'lib/assets/images/country-flags/Kazakhstan.svg';
import { ReactComponent as LatviaFlag } from 'lib/assets/images/country-flags/Latvia.svg';
import { ReactComponent as LithuaniaFlag } from 'lib/assets/images/country-flags/Lithuania.svg';
import { ReactComponent as LuxembourgFlag } from 'lib/assets/images/country-flags/Luxembourg.svg';
import { ReactComponent as MalaysiaFlag } from 'lib/assets/images/country-flags/Malaysia.svg';
import { ReactComponent as MexicoFlag } from 'lib/assets/images/country-flags/Mexico.svg';
import { ReactComponent as NetherlandsFlag } from 'lib/assets/images/country-flags/Netherlands.svg';
import { ReactComponent as NewZealandFlag } from 'lib/assets/images/country-flags/NewZealand.svg';
import { ReactComponent as NorwayFlag } from 'lib/assets/images/country-flags/Norway.svg';
import { ReactComponent as PeruFlag } from 'lib/assets/images/country-flags/Peru.svg';
import { ReactComponent as PolandFlag } from 'lib/assets/images/country-flags/Poland.svg';
import { ReactComponent as RomaniaFlag } from 'lib/assets/images/country-flags/Romania.svg';
import { ReactComponent as RussiaFlag } from 'lib/assets/images/country-flags/Russia.svg';
import { ReactComponent as SerbiaFlag } from 'lib/assets/images/country-flags/Serbia.svg';
import { ReactComponent as SingaporeFlag } from 'lib/assets/images/country-flags/Singapore.svg';
import { ReactComponent as SlovakiaFlag } from 'lib/assets/images/country-flags/Slovakia.svg';
import { ReactComponent as SloveniaFlag } from 'lib/assets/images/country-flags/Slovenia.svg';
import { ReactComponent as SouthKoreaFlag } from 'lib/assets/images/country-flags/SouthKorea.svg';
import { ReactComponent as SpainFlag } from 'lib/assets/images/country-flags/Spain.svg';
import { ReactComponent as SwedenFlag } from 'lib/assets/images/country-flags/Sweden.svg';
import { ReactComponent as SwitzerlandFlag } from 'lib/assets/images/country-flags/Swizerland.svg';
import { ReactComponent as TaiwanFlag } from 'lib/assets/images/country-flags/Taiwan.svg';
import { ReactComponent as CzechRepublicFlag } from 'lib/assets/images/country-flags/TheCzechRepublic.svg';
import { ReactComponent as TurkeyFlag } from 'lib/assets/images/country-flags/Turkey.svg';
import { ReactComponent as UkraineFlag } from 'lib/assets/images/country-flags/Ukraine.svg';
import { ReactComponent as UnitedStatesFlag } from 'lib/assets/images/country-flags/US.svg';

interface Country {
  countryName: string;
  countryCode: string;
  phoneCode: string;
  flag?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const countries: Country[] = [
  {
    countryName: 'Australia',
    countryCode: 'AU',
    phoneCode: '+61',
    flag: AustraliaFlag,
  },
  {
    countryName: 'Austria',
    countryCode: 'AT',
    phoneCode: '+43',
    flag: AustriaFlag,
  },
  {
    countryName: 'Belgium',
    countryCode: 'BE',
    phoneCode: '+32',
    flag: BelgiumFlag,
  },
  {
    countryName: 'Brazil',
    countryCode: 'BR',
    phoneCode: '+55',
    flag: BrazilFlag,
  },
  {
    countryName: 'Bulgaria',
    countryCode: 'BG',
    phoneCode: '+359',
    flag: BulgariaFlag,
  },
  {
    countryName: 'Canada',
    countryCode: 'CA',
    phoneCode: '+1',
    flag: CanadaFlag,
  },
  {
    countryName: 'Chile',
    countryCode: 'CL',
    phoneCode: '+56',
    flag: ChileFlag,
  },
  {
    countryName: 'China',
    countryCode: 'CN',
    phoneCode: '+86',
    flag: ChinaFlag,
  },
  {
    countryName: 'Colombia',
    countryCode: 'CO',
    phoneCode: '+57',
    flag: ColombiaFlag,
  },
  {
    countryName: 'Croatia',
    countryCode: 'HR',
    phoneCode: '+385',
    flag: CroatiaFlag,
  },
  {
    countryName: 'Denmark',
    countryCode: 'DK',
    phoneCode: '+45',
    flag: DenmarkFlag,
  },
  {
    countryName: 'Dubai',
    countryCode: 'AE',
    phoneCode: '+971',
    flag: DubaiFlag,
  },
  {
    countryName: 'Estonia',
    countryCode: 'EE',
    phoneCode: '+372',
    flag: EstoniaFlag,
  },
  {
    countryName: 'Finland',
    countryCode: 'FI',
    phoneCode: '+358',
    flag: FinlandFlag,
  },
  {
    countryName: 'France',
    countryCode: 'FR',
    phoneCode: '+33',
    flag: FranceFlag,
  },
  {
    countryName: 'Germany',
    countryCode: 'DE',
    phoneCode: '+49',
    flag: GermanyFlag,
  },
  {
    countryName: 'Great Britain',
    countryCode: 'GB',
    phoneCode: '+44',
    flag: GreatBritainFlag,
  },
  {
    countryName: 'Greece',
    countryCode: 'GR',
    phoneCode: '+30',
    flag: GreeceFlag,
  },
  {
    countryName: 'Hong Kong',
    countryCode: 'HK',
    phoneCode: '+852',
    flag: HongKongFlag,
  },
  {
    countryName: 'Hungary',
    countryCode: 'HU',
    phoneCode: '+36',
    flag: HungaryFlag,
  },
  {
    countryName: 'India',
    countryCode: 'IN',
    phoneCode: '+91',
    flag: IndiaFlag,
  },
  {
    countryName: 'Indonesia',
    countryCode: 'ID',
    phoneCode: '+62',
    flag: IndonesiaFlag,
  },
  {
    countryName: 'Ireland',
    countryCode: 'IE',
    phoneCode: '+353',
    flag: IrelandFlag,
  },
  {
    countryName: 'Italy',
    countryCode: 'IT',
    phoneCode: '+39',
    flag: ItalyFlag,
  },
  {
    countryName: 'Japan',
    countryCode: 'JP',
    phoneCode: '+81',
    flag: JapanFlag,
  },
  {
    countryName: 'Kazakhstan',
    countryCode: 'KZ',
    phoneCode: '+7',
    flag: KazakhstanFlag,
  },
  {
    countryName: 'Latvia',
    countryCode: 'LV',
    phoneCode: '+371',
    flag: LatviaFlag,
  },
  {
    countryName: 'Lithuania',
    countryCode: 'LT',
    phoneCode: '+370',
    flag: LithuaniaFlag,
  },
  {
    countryName: 'Luxembourg',
    countryCode: 'LU',
    phoneCode: '+352',
    flag: LuxembourgFlag,
  },
  {
    countryName: 'Malaysia',
    countryCode: 'MY',
    phoneCode: '+60',
    flag: MalaysiaFlag,
  },
  {
    countryName: 'Mexico',
    countryCode: 'MX',
    phoneCode: '+52',
    flag: MexicoFlag,
  },
  {
    countryName: 'Netherlands',
    countryCode: 'NL',
    phoneCode: '+31',
    flag: NetherlandsFlag,
  },
  {
    countryName: 'New Zealand',
    countryCode: 'NZ',
    phoneCode: '+64',
    flag: NewZealandFlag,
  },
  {
    countryName: 'Norway',
    countryCode: 'NO',
    phoneCode: '+47',
    flag: NorwayFlag,
  },
  {
    countryName: 'Peru',
    countryCode: 'PE',
    phoneCode: '+51',
    flag: PeruFlag,
  },
  {
    countryName: 'Poland',
    countryCode: 'PL',
    phoneCode: '+48',
    flag: PolandFlag,
  },
  {
    countryName: 'Romania',
    countryCode: 'RO',
    phoneCode: '+40',
    flag: RomaniaFlag,
  },
  {
    countryName: 'Russia',
    countryCode: 'RU',
    phoneCode: '+7',
    flag: RussiaFlag,
  },
  {
    countryName: 'Serbia',
    countryCode: 'RS',
    phoneCode: '+381',
    flag: SerbiaFlag,
  },
  {
    countryName: 'Singapore',
    countryCode: 'SG',
    phoneCode: '+65',
    flag: SingaporeFlag,
  },
  {
    countryName: 'Slovakia',
    countryCode: 'SK',
    phoneCode: '+421',
    flag: SlovakiaFlag,
  },
  {
    countryName: 'Slovenia',
    countryCode: 'SI',
    phoneCode: '+386',
    flag: SloveniaFlag,
  },
  {
    countryName: 'South Korea',
    countryCode: 'KR',
    phoneCode: '+82',
    flag: SouthKoreaFlag,
  },
  {
    countryName: 'Spain',
    countryCode: 'ES',
    phoneCode: '+34',
    flag: SpainFlag,
  },
  {
    countryName: 'Sweden',
    countryCode: 'SE',
    phoneCode: '+46',
    flag: SwedenFlag,
  },
  {
    countryName: 'Switzerland',
    countryCode: 'CH',
    phoneCode: '+41',
    flag: SwitzerlandFlag,
  },
  {
    countryName: 'Taiwan',
    countryCode: 'TW',
    phoneCode: '+886',
    flag: TaiwanFlag,
  },
  {
    countryName: 'Czech Republic',
    countryCode: 'CZ',
    phoneCode: '+420',
    flag: CzechRepublicFlag,
  },
  {
    countryName: 'Turkey',
    countryCode: 'TR',
    phoneCode: '+90',
    flag: TurkeyFlag,
  },
  {
    countryName: 'Ukraine',
    countryCode: 'UA',
    phoneCode: '+380',
    flag: UkraineFlag,
  },
  {
    countryName: 'United States',
    countryCode: 'US',
    phoneCode: '+1',
    flag: UnitedStatesFlag,
  },
];
