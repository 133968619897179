import styled from 'styled-components';

export const StyledSentInvitations = styled.div`
  margin-top: ${(props): string => props.theme.spacing.xl};

  .sent-invitations__title {
    ${(props): string => props.theme.fontStyles.heading3}
    margin-bottom: 0.7em;
  }

  .sent-invitations__mail-icon {
    width: 24px;
    height: 24px;
  }

  .sent-invitations__mail-button {
    width: 24px;
    height: 24px;
    padding: 0;
  }

  .sent-invitations__mail-button--disabled {
    cursor: auto;
  }

  .sent-invitations__mail-cell {
    display: flex;
    justify-content: flex-end;
  }

  .ant-table-cell {
    padding: 7px ${(props): string => props.theme.spacing.sm} !important;
  }
`;
