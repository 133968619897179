import { ApolloQueryResult, FetchResult, gql } from '@apollo/client';
import { inject, injectable } from 'tsyringe';
import { UserDeleteResponse, UserInviteResponse, UserListResponse, UserUpdateResponse } from './interfaces/User.types';
import { GraphQlClient } from 'app/cross-cutting-concerns/communication/clients/GraphQlClient';
import {
  MutationUserDeleteByEmailArgs,
  MutationUserInviteArgs,
  MutationUserUpdateArgs,
  QueryUsersArgs,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export type UserClientListOptions = QueryUsersArgs;
export type UserClientUpdateOptions = MutationUserUpdateArgs;
export type UserClientInviteOptions = MutationUserInviteArgs;
export type UserClientDeleteOptions = MutationUserDeleteByEmailArgs;

@injectable()
export class UserClient {
  constructor(@inject('GraphQlClient') private client: GraphQlClient) {}
  public list = async ({
    filter,
    paginationOptions,
  }: UserClientListOptions): Promise<ApolloQueryResult<UserListResponse>> =>
    this.client.query({
      query: gql`
        query Users($filter: InputFilterUsersList, $paginationOptions: InputPagingOptions!) {
          users(filter: $filter, paginationOptions: $paginationOptions) {
            data {
              createdAt
              displayName
              id
              email
              role
              status
            }
            metadata {
              paginationToken
              totalCount
            }
          }
        }
      `,
      variables: {
        filter,
        paginationOptions,
      },
    });

  public update = async ({ id, input }: UserClientUpdateOptions): Promise<FetchResult<UserUpdateResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation UserUpdate($id: ID!, $input: InputUserUpdate!) {
          userUpdate(id: $id, input: $input) {
            data {
              id
              displayName
              email
              role
              status
              createdAt
            }
          }
        }
      `,
      variables: {
        id,
        input,
      },
    });

  public invite = async ({ input }: UserClientInviteOptions): Promise<FetchResult<UserInviteResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation UserUpdate($input: InputUserInvite!) {
          userInvite(input: $input) {
            data
          }
        }
      `,
      variables: {
        input,
      },
    });

  public delete = async ({ input }: UserClientDeleteOptions): Promise<FetchResult<UserDeleteResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation UserDeleteByEmail($input: InputUserDeleteByEmail!) {
          userDeleteByEmail(input: $input) {
            data
          }
        }
      `,
      variables: {
        input,
      },
    });
}
