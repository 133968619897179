import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';

export const selectDeleteOperatorModalVisible = (state: IState): boolean =>
  state.modules['user-management'].modals.operator.operatorDelete.visible;

export const selectDeleteOperatorModalIsLoading = (state: IState): boolean =>
  state.modules['user-management'].modals.operator.operatorDelete.isLoading;

export const selectDeleteOperatorId = (state: IState): string =>
  state.modules['user-management'].modals.operator.operatorDelete.operatorId;

// delete user
export const selectDeleteUserModalVisible = (state: IState): boolean =>
  state.modules['user-management'].modal.deleteUser.visible;

export const selectDeleteUserModalIsLoading = (state: IState): boolean =>
  state.modules['user-management'].modal.deleteUser.isLoading;

export const selectDeleteUserEmail = (state: IState): string => state.modules['user-management'].modal.deleteUser.email;

export const selectDeletingEmail = (state: IState): { [key: string]: string } =>
  state.modules['user-management'].modal.deleteUser.deletingEmail;
