export const PRODUCT_NAME = 'Equipment Management Workspace';
export const PRODUCT_NAME_TITLE = 'Equipment Management';
export const PAGE_SIZE_OPTIONS = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '30',
    value: 30,
  },
  {
    label: '50',
    value: 50,
  },
];

export const DEFAULT_PAGE_SIZE_VALUE = PAGE_SIZE_OPTIONS[0].value;

export const DEFAULT_PAGE_VALUE = 1;

// See https://spec.graphql.org/June2018/#sec-Int
export const GRAPHQL_MAX_INT_VALUE = 2 ** 31 - 1;

export const InfiniteScrollConstants = {
  MAX_ITEMS: 300,
  MAX_EVENT_ITEMS: 1000,
  DEFAULT_HEIGHT: 600,
};

export const OpenSearch = {
  MAX_RESULT_WINDOW: 10000,
  Int: {
    // Maximum value for ElasticSearch Int type
    // https://www.elastic.co/guide/en/elasticsearch/reference/current/number.html
    MAXIMUM_VALUE: 2147483647,
  },
};

export const MAP_BOUNDING_BOX = {
  ne: {
    lat: 90,
    lng: 180,
  },
  sw: {
    lat: -90,
    lng: -180,
  },
};

export const DEFAULT_ADDRESSES_SUGGESTION_LIMIT_VALUE = 5;

export const POLL_MAX_RETRIES = 10;
export const POLL_INTERVAL = 1000;
export const VNC_POLL_MAX_RETRIES = 1200; // Polling for 10 minutes
export const VNC_POLL_MAX_RETRIES_V2 = 66; // Polling for 33 seconds
export const VNC_POLL_INTERVAL = 500;

export const ALL_VALUE_SELECT_OPTION = '';

export const TAGS_MAX_LENGTH = 20;
export const TAGS_MAX_SIZE = 20;
export const TAGS_SEARCH_LIMIT = 20;
export const TAGS_SEARCH_DELAY_IN_MS = 500;

export const FILE_UPLOAD_MAX_NO_FILES = 10;
export const FILE_UPLOAD_DEFAULT_MAX_FILE_SIZE = 10 * 1024 * 1024;

// TODO: add groupby version
export const ROBOT_LIST_GROUP_BY = {
  status: 'status',
  site: 'site',
};

export const UNASSIGNED = 'Unassigned';
export const SOFTWARE_VERSION_CONNECT_VNC_MAJOR = 2;
export const SOFTWARE_VERSION_CONNECT_VNC_MINOR = 1;

export const LOCAL_STORAGE_VNC_KEY = 'current-robot-session';
export const LOCAL_STORAGE_VNC_SERIAL_NUMBER = 'current-robot-serial-number';
export const LOCAL_STORAGE_VNC_ROBOT_SOFTWARE_VERSION_KEY = 'current-robot-software-version';
export const LOCAL_STORAGE_IS_USING_VNC_V2_KEY = 'is-using-vnc-v2';

export const MAXIMUM_ODOMETER_DIGIT = 10; // constant to support odometer animation
