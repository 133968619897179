import { StyledContractObligationPage } from './ContractObligationPage.styles';
import { AppFooter } from 'app/components/app-layout/AppFooter/AppFooter';
import { AppHeader } from 'app/components/app-layout/AppHeader/AppHeader';
import { ContractObligation } from 'app/modules/terms-conditions/components/ContractObligation/ContractObligation';

export const ContractObligationPage = (): JSX.Element => (
  <StyledContractObligationPage>
    <AppHeader />
    <ContractObligation />
    <AppFooter />
  </StyledContractObligationPage>
);
